import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchSettings: "FETCH_SETTINGS",
    receiveSettings: "RECEIVE_SETTINGS"
};

const receiveSettings = (settings) => ({ type: actionTypes.receiveSettings, settings });

export const fetchSettings = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchSettings });

        const http = new HttpService();

        return http.getData(`settings`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(settings => {
                dispatch(receiveSettings(settings));
            })
    }
}