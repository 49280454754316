import { Email, Error, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  cleanPhysician, createPhysician, } from '../actions/signup';
import { selectOrigins } from '../selectors/catalogs';
import { isPhysicianRegistryEnabled } from '../selectors/settings';
import { selectSignupData } from '../selectors/signup';
import OriginsDialog from './OriginsDialog';


const PhysicianSignup = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signupData = useSelector(selectSignupData);
    
    const [userData, setUserData] = useState({ first_name: '', last_name: '', email: '', password:'', confirmationPassword :'', license:''});
    const [passVisibility, setPassVisivility] = useState(false);
    const [confirmationVisibility, setConfirmationVisivility] = useState(false);

    const physiciansEnabled = useSelector(isPhysicianRegistryEnabled);


    const handleSave = () => {
        dispatch(createPhysician(userData));
    };


    const validForm = userData.email != '' && userData.password != '' && userData.password === userData.confirmationPassword && userData.first_name != '' && userData.last_name != '';
    const passwordInvalid = userData.password != '' && userData.confirmationPassword != '' && userData.password != userData.confirmationPassword;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Formulario de Alta</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em' }}>

                    {
                        !physiciansEnabled &&
                        <div style={{ marginBottom: '1em' }}>
                            <Typography>Por el momento la funcion de registro esta inhablitada</Typography>
                        </div>
                    }
                    <div style={{ marginBottom: '1em' }}>
                        <TextField 
                            id="usr_1"
                            size="small" 
                            variant="outlined"
                            placeholder="Email"
                            fullWidth
                            disabled={!physiciansEnabled}
                            value={userData.email}
                            onChange={(event) => setUserData({ ...userData, email: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            id="usr_2"
                            size="small"
                            type={!passVisibility ? "password" : "text"}
                            variant="outlined"
                            placeholder="Contraseña"
                            fullWidth
                            value={userData.password}
                            disabled={!physiciansEnabled}
                            onChange={(event) => setUserData({ ...userData, password: event.target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPassVisivility(!passVisibility)}
                                    >
                                        {!passVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>}

                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            id="usr_3"
                            size="small"
                            type={!confirmationVisibility ? "password" : "text"}
                            variant="outlined"
                            placeholder="Confirmar contraseña"
                            fullWidth
                            disabled={!physiciansEnabled}
                            error={passwordInvalid && 'error'}
                            helperText={passwordInvalid && 'La confirmacion no coincide'} 
                            value={userData.confirmationPassword}
                            onChange={(event) => setUserData({ ...userData, confirmationPassword: event.target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setConfirmationVisivility(!confirmationVisibility)}
                                    >
                                        {!confirmationVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>}
                        />
                    </div>
  
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            id="firtName"
                            size="small"
                            variant="outlined"
                            placeholder="Nombre"
                            fullWidth
                            disabled={!physiciansEnabled}
                            value={userData.firstName}
                            onChange={(event) => setUserData({ ...userData, first_name: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            id="lastName"
                            size="small"
                            variant="outlined"
                            placeholder="Apellido"
                            fullWidth
                            disabled={!physiciansEnabled}
                            value={userData.lastName}
                            onChange={(event) => setUserData({ ...userData, last_name: event.target.value })} />
                    </div>
                    
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            id="license"
                            size="small"
                            variant="outlined"
                            placeholder="Matricula"
                            fullWidth
                            disabled={!physiciansEnabled}
                            value={userData.license}
                            onChange={(event) => setUserData({ ...userData, license: event.target.value })} />
                    </div>

                    <div style={{ marginBottom: '1em' }}>
                        <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            disabled={!validForm || !physiciansEnabled || signupData?.physicianUserStatus?.created}
                            onClick={() => handleSave()}>Continuar</Button>
                    </div>

                {  
                    signupData?.physicianUserStatus?.created &&
                    <div style={{ height: '1em', display:'flex',flexDirection:'column', alignItems:'center'}}>
                        <Email color="primary" style={{marginBottom:'1em'}}></Email>
                        <Typography textAlign="center" variant="body1" color="primary" >Listo!</Typography>
                        <Typography textAlign="center" variant="body1" color="primary" >Revisa tu email y confirmanos el alta.</Typography>
                    </div>
                }

                {  
                    signupData?.physicianUserStatus?.error &&
                    <div style={{ height: '1em', display:'flex',flexDirection:'column', alignItems:'center'}}>
                        <Error color="primary" style={{marginBottom:'1em'}}></Error>
                        <Typography textAlign="center" variant="body1" color="primary" >Ha ocurrido un error.</Typography>
                        <Typography textAlign="center" variant="body1" color="primary" >El email ingresado ya esta registrado.</Typography>
                    </div>
                }

                    <div style={{ height: '1em' }}>

                        {   
                            signupData?.physicianUserStatus?.creating &&
                            <LinearProgress color="primary" />
                        }
                    </div>

                </div>
            </div>
        </div >
    );
};

export default PhysicianSignup;