import { createStore, compose, applyMiddleware } from 'redux';

import thunk from 'redux-thunk';
import { fetchOrigins } from '../actions/catalogs';
import { fetchSettings } from '../actions/settings';
import reducers from '../reducers';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(reducers, {},
    composeEnhancers(applyMiddleware(thunk)));

store.dispatch(fetchOrigins());
store.dispatch(fetchSettings());