import React from 'react';
import { Box, Divider, Grid, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles(theme => ({
    root: {
        width: 100,
        '& .MuiSlider-valueLabelOpen': {
            backgroundColor: '#edf1f4',
            color: 'black'
        },
        '& .MuiSlider-mark': {
            color: '#edf1f4',
            height: 2,
            width: 3,
            '&.MuiSlider-markActive': {
                opacity: 1,
                backgroundColor: 'currentColor',
            },
        },

    },
    margin: {
        height: theme.spacing(3)
    },
    thumb1: {
        color: theme.palette.status.available
    },
    mark: {
        height: '2em',
        color: 'black'
    },
    rail: {
        height: '0.5em',
        color: theme.palette.status.available
    },
    track: {
        height: '0.5em',
        color: theme.palette.status.available
    }
}));
const ReferenceValue = ({ result }) => {

    const classes = useStyles();

    const dif = result?.reference_value?.max - result?.reference_value.min;
    const limits = {
        min: parseFloat(result?.reference_value?.min) - (dif * 100 / 80),
        max: parseFloat(result?.reference_value?.max) + (dif * 100 / 80)
    }


    return (
        <Grid direction="column" >
            <Typography><Box fontWeight={520}>Referencia</Box></Typography>
            <Divider />
            <Slider
                valueLabelDisplay="on"
                valueLabelFormat={result.value}
                value={result?.value}
                step={1}
                marks={[{ label: `${result.reference_value.min}`, value: parseFloat(result.reference_value.min) }, { label: `${result.reference_value.max}`, value: parseFloat(result.reference_value.max) }]}
                min={parseFloat(limits?.min)}
                max={parseFloat(limits?.max)}
                color="secondary"
                height={2}
                classes={{
                    root: classes.root,
                    thumb: classes.thumb,
                    rail: classes.rail,
                    track: classes.track,
                    mark: classes.mark,
                    valueLabel: classes.valueLabel,
                }}
                style={{ marginTop: '3em', width: '100%' }}

            />
        </Grid>

    );
};

export default ReferenceValue;