import React from 'react';
import { Box, Divider, Grid, Paper, Typography } from '@mui/material';
import { Area, AreaChart, CartesianGrid, Tooltip, XAxis, YAxis } from 'recharts';
import { useTheme } from '@mui/styles';
import { format, parseISO } from 'date-fns';

const FormatedTip = (data) => {

    const theme = useTheme();
    const decimals = data?.payload[0]?.payload.Decimals;
    const value = data?.payload[0]?.payload.Valor;
    const date = data?.payload[0]?.payload.Fecha;
    const floatValue = parseFloat(value) && value.toLocaleString(navigator.language, { minimumFractionDigits: decimals });

    return (<Paper style={{ padding: '0.5em' }}>
        <div>Fecha: {date}</div>
        <div style={{ color: theme.palette.arenco.darkBlue }}>Valor: {floatValue || value}</div>
    </Paper>)
};


const History = ({ test }) => {


    const theme = useTheme();
    const hasTextResult = test?.history?.some(test => isNaN(test.result.value));
    const data = hasTextResult ? [] : test?.history?.map(test => ({ 'Decimals': test?.result?.decimals, 'Valor': parseFloat(test.result.value), 'Fecha': format(parseISO(test.order_date), 'dd/MM/yyyy') }))

    const formater = (value) => {
        return value.toLocaleString(navigator.language);
    }

    return (
        <Grid direction="column" >
            <Typography><Box fontWeight={520}>Historia</Box></Typography>
            <Divider />

            {
                !hasTextResult && data?.length > 0 &&
                <AreaChart width={400} height={200} data={[...data, { 'Decimals': test?.result?.decimals, 'Valor': parseFloat(test.result.value), 'Fecha': 'Actual' }]} margin={{ top: 40, right: 30, left: 30, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Fecha" padding={{ top: '1em' }} tick={false} />
                    <YAxis tickMargin={10} tickFormatter={formater} />
                    <Tooltip content={FormatedTip} />
                    <Area type="monotone" dataKey="Valor" fill={theme.palette.secondary.main} fillOpacity={3} stroke={theme.palette.primary.main} />
                </AreaChart>
            }

            {
                hasTextResult && test.history.length > 0 &&
                test?.history?.map(test =>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <strong>{test.result.value}</strong>
                        <div>{format(parseISO(test.released), 'dd/MM/yyyy')}</div>
                    </div>
                )

            }

            {
                test.history.length === 0 &&
                <Typography>No hay antecedentes</Typography>
            }



        </Grid >
    );
};

export default History;