import { combineReducers } from 'redux';
import { actionTypes } from '../actions/notificationLogs';


const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchNotificationLogs:
            return true
        case actionTypes.receiveNotificationLogs:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchNotificationLogs:
            return []
        case actionTypes.clearNotificationLogs:
            return []
        case actionTypes.receiveNotificationLogs:
            return action.notificationLogs
        default:
            return state
    }
}

export default combineReducers({ loading, data });