import React, { useState } from "react";
import { Button, Grid, MenuItem,  TextField, Typography } from "@mui/material";

const rolesOptions = [
    {
        id:4,
        name:'Todos',
        filter:''
    },
    {
        id:1,
        code:'admin',
        name:'Administrator ROLE',
        filter:'role_id=1'
    },
    {
        id:2,
        code:'physician',
        name:'Physician ROLE',
        filter:'role_id=2'

    },
    {
        id:3,
        code:'patient',
        name:'Patient ROLE',
        filter:'role_id=3'
    }
];

const enabledOptions = [
    {
        id:3,
        name:'Todos',
        filter:''
    },
    {
        id:1,
        name:'Solo activos',
        filter:'enabled=true'
    },
    {
        id:2,
        name:'Solo inactivos',
        filter:'enabled=false'
    }
]

const registryOptions = [
    {
        id:3,
        name:'Todos',
        filter:''
    },
    {
        id:1,
        name:'Solo registrados',
        filter:'email_verified_at=true'
    },
    {
        id:2,
        name:'Solo No registrados',
        filter:'email_verified_at=false'
    }
];

const queryParams = (filter) => {

    let params = [];

    params.push(enabledOptions.filter(option => option.id === filter.enabled_id)[0].filter);
    params.push(registryOptions.filter(option => option.id === filter.registry_id)[0].filter);
    params.push(rolesOptions.filter(option => option.id === filter.role_id)[0].filter);
    params.push(`name=${filter.name}`)
    params.push(`email_or_whatsapp=${filter.email_or_whatsapp}`)

    return params.filter(word=>word !== '');
}

const UserFilter = ({disabled,initFilter, onSearch}) => {

    const [filter,setFilter] = useState(initFilter);

    const handleChange = (event) => {
        setFilter({...filter, [event.target.name]:  event.target.value});
    }

    const handleSearch = () => {
        onSearch(queryParams(filter));
    }

    return <Grid container direction={"column"} >

                <Grid item xs={1} style={{marginBottom:'0.5em'}}>
                    <Typography variant="h6">Busqueda</Typography>
                </Grid>

                <Grid item container spacing={1}>

                    <Grid item xs={6} container direction={"column"} spacing={1}>
                        <Grid item> 
                            <TextField id="email_or_whatsapp" size="small" name="email_or_whatsapp" label="Email o whatsapp" fullWidth value={filter.email_or_whatsapp || ''} onChange={handleChange} disabled={disabled} />
                        </Grid>

                        <Grid item> 
                            <TextField id="name" size="small" name="name" label="Nombre o Apellido" fullWidth value={filter.name || ''} onChange={handleChange} disabled={disabled}/>
                        </Grid>

                        <Grid item xs container spacing={1}>
                            <Grid item>
                                <Button variant="outlined" fullWidth onClick={()=>setFilter(initFilter)}>LIMPIAR</Button>
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" fullWidth onClick={handleSearch}>BUSCAR</Button>
                            </Grid>
                        </Grid>


                    </Grid>

                    <Grid item xs container direction={"column"} spacing={1}>
                        <Grid item > 
                            <TextField id="registry_id" size="small" name="registry_id" label="Registro" fullWidth select value={filter.registry_id || ''} onChange={handleChange} disabled={disabled}>
                                {
                                    registryOptions.map(registry => (
                                        <MenuItem key={registry.id} value={registry.id}>
                                            {registry.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        <Grid item > 
                            <TextField id="enabled_id" size="small" name="enabled_id" label="Estado" fullWidth select value={filter.enabled_id || ''} onChange={handleChange} disabled={disabled}>
                                {
                                    enabledOptions.map(enabled => (
                                        <MenuItem key={enabled.id} value={enabled.id}>
                                            {enabled.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>

                        <Grid item > 
                            <TextField id="role_id" size="small" name="role_id" label="Rol" fullWidth select value={filter.role_id || ''} onChange={handleChange} disabled={disabled}>
                                {
                                    rolesOptions.map(role => (
                                        <MenuItem key={role.id} value={role.id}>
                                            {role.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                    
                    
                </Grid>
        </Grid>
}

export default UserFilter;
