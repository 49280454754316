import HttpService from "../services/HttpService";

export const actionTypes = {
    setTokenStatus: "SET_TOKEN_STATUS",
    setUserStatus: 'SET_USER_STATUS',
    setPasswordStatus: 'SET_PASSWORD_STATUS',
    createPhysician: 'CREATE_PHYSICIAN',
    confirmPhysician: 'CONFIRM_PHYSICIAN',
    cleanPhysician:'CLEAN_PHYSICIAN',
    validateEmail:'VALIDATE_EMAIL',
    validatingEmail:'VALIDATING_EMAIL',
    rejectEmail:'REJECT_EMAIL'
};

export const setToken = (token) => ({ type: actionTypes.set_token, token })

export const validateToken = (token) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.setTokenStatus, tokenStatus: { valid: false, validating: true } });

        const http = new HttpService();
        return http.getData(`custom/signup-management/validate-token/${token}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(tokenStatus => {
                dispatch({ type: actionTypes.setTokenStatus, tokenStatus: { userVerified: tokenStatus.user_verified,  valid: tokenStatus.valid_token, expired: tokenStatus.expired_token,validation: false } })
            })
    }
}

export const clearRecoverToken = () => ({ type: actionTypes.clearEmail })

export const recoverToken = (email) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.validatingEmail});

        const http = new HttpService();
        return http.postData({"email":email},`custom/signup-management/recover-registration-token`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {

                response.success && dispatch({ type: actionTypes.validateEmail});
                !response.success && dispatch({ type: actionTypes.rejectEmail, error:response.error, code:response.code });
                
            })
    }
}

export const confirmUser = (data) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.setUserStatus, userStatus: { valid: false, validating: true } });

        const http = new HttpService();
        return http.postData(data, `custom/signup-management/confirm-user`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(confirmation => {
                dispatch({ type: actionTypes.setUserStatus, userStatus: { valid: confirmation.succes, validating: false, error: confirmation.error } });
            })
    }
}

export const createPassword = (password, signupToken) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.setPasswordStatus, passwordStatus: { created: false, creating: true } });

        const http = new HttpService();
        return http.postData({ password: password, signupToken: signupToken }, `custom/signup-management/create-password`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(user => {
                dispatch({ type: actionTypes.setPasswordStatus, passwordStatus: { created: true, creating: false } });
            })
    }
}

export const cleanPhysician = () => ({ type: actionTypes.cleanPhysician })


export const createPhysician = (data) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.createPhysician});

        const http = new HttpService();
        return http.postData(data, `custom/signup-management/create-physician-user`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {
                dispatch({ type: actionTypes.confirmPhysician, physicianUserStatus: { created: response.succes, creating: false, error:response.error } });
            })

    }
}