import { TextField } from "@mui/material";
import React, { useState } from "react";

const MobileDate = ({onSet}) => {

    const [date,setDate]=useState({day:'', month:'', year:''});
    const handleChange = (event) =>{
        setDate({...date, [event.target.id]:event.target.value});
        onSet({...date, [event.target.id]:event.target.value});
    }

    return(<div style={{display:'flex'}}>
        <TextField id="day" label="Dia" value={date.day} onChange={handleChange} size="small" style={{width:'4em', marginRight:'0.5em'}}></TextField>
        <TextField id="month" label="Mes" value={date.month} onChange={handleChange} size="small" style={{width:'4em', marginRight:'0.5em'}}></TextField>
        <TextField id="year" label="Año" value={date.year} onChange={handleChange} size="small" ></TextField>
    </div>)

};

export default MobileDate; 