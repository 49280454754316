
export const getInitials = (fullname) => {

    if (fullname) {
        const names = fullname.split(' ');

        if (names.length === 1) {
            return names[0]?.substring(0, 1);
        }
        else if (names.length > 1) {
            return names[0]?.substring(0, 1) + names[1]?.substring(0, 1);
        }
    }
    else
        return '';

}

