import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createPassword, validateToken } from '../actions/signup';
import { selectSignupData } from '../selectors/signup';

const PatientSignupPassword = () => {

    const { key } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signupData = useSelector(selectSignupData);
    const [data, setData] = useState({ password: '', confirmationPassword: '' });

    const [passVisibility, setPassVisivility] = useState(false);
    const [confirmationVisibility, setConfirmationVisivility] = useState(false);

    const validPassword =  (data.password === data.confirmationPassword);
    const typedPassword = data.password !== '' && data.confirmationPassword !== ''

    useEffect(() => {
        dispatch(validateToken(key));
    }, []);


    useEffect(() => {
        if (signupData.passwordStatus.created && !signupData.userStatus.creating) {
            navigate('/');
        }
    }, [signupData.passwordStatus]);


    const handleSave = () => {
        dispatch(createPassword(data.password, key));
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Generacion de clave</Box></Typography>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {
                    signupData.tokenStatus.valid && !signupData.tokenStatus.expired && !signupData.tokenStatus.userVerified && 
                    <div style={{ height: '4em' }}>
                        <Typography>Estas a un paso de confirmar el registro.</Typography>
                        <Typography>Elegi tu contraseña.</Typography>
                    </div>
                }

                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em' }}>

                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            size="small"
                            type={!passVisibility ? "password" : "text"}
                            variant="outlined"
                            placeholder="Contraseña"
                            fullWidth
                            value={data.password}
                            onChange={(event) => setData({ ...data, password: event.target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setPassVisivility(!passVisibility)}
                                    >
                                        {!passVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>}

                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            size="small"
                            type={!confirmationVisibility ? "password" : "text"}
                            variant="outlined"
                            placeholder="Confirmar contraseña"
                            fullWidth
                            value={data.confirmationPassword}
                            onChange={(event) => setData({ ...data, confirmationPassword: event.target.value })}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setConfirmationVisivility(!confirmationVisibility)}
                                    >
                                        {!confirmationVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>}
                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            onClick={() => handleSave()}
                            disabled={(!validPassword && typedPassword) || !signupData?.tokenStatus.valid || signupData?.tokenStatus.expired}>Guardar clave</Button>
                    </div>
                    <div style={{ height: '1em' }}>
                        {(signupData.passwordStatus.creating  || signupData.tokenStatus.validating) &&
                            <LinearProgress color="primary" />
                        }
                    </div>

                    {
                        !validPassword && typedPassword &&

                        <div style={{ height: '1em' }}>
                            <Typography>Ambas contraseñas deben coincidir</Typography>
                        </div>
                    }


                    {
                        signupData.tokenStatus.userVerified && !signupData.tokenStatus.validating &&
                        <div style={{ height: '1em' }}>
                            <Typography>Usted ya esta registrado. Dirijase al login y si es necesario recupere su contraseña.</Typography>
                            <Link to="/" style={{textDecoration:'none'}}>
                                <Button size="small" variant="outlined" fullWidth>Login</ Button>
                            </Link>
                        </div>
                    }

                    {
                        signupData.tokenStatus.expired &&
                        <div style={{ height: '2em' }}>
                            <Typography>El link de registro tiene una vigencia de 90 dias y esta vencido.</Typography>
                            <Typography>Hemos enviado un nuevo link de registro a tu correo.</Typography>
                        </div>
                    }

                    {
                        !signupData.tokenStatus.valid && !signupData.tokenStatus.validating && 
                        <div style={{ height: '1em' }}>
                            <Typography>Los datos ingresados no corresponden con los registrados.</Typography>
                        </div>
                    }

                </div>
            </div>
        </div >);
};

export default PatientSignupPassword;