import { combineReducers } from 'redux';
import { actionTypes } from '../actions/intervals';

const months = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchMonthInterval:
            return []
        case actionTypes.receiveMonthInterval:
            return action.interval
        default:
            return state
    }
}

export default combineReducers({ months });

