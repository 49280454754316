import physicians from "../reducers/physicians";
import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchPhysician: "FETCH_PHYSICIAN",
    clearPhysicians: "CLEAR_PHYSICIANS",
    searchPhysician: "SEARCH_PHYSICIAN",
    receivePhysician: "RECEIVE_PHYSICIAN",
    receivePhysicians: "RECEIVE_PHYSICIANS"
};

const receivePhysicians = (physicians) => ({ type: actionTypes.receivePhysicians, physicians });
const receivePhysician = (physician) => ({ type: actionTypes.receivePhysician, physician });

export const clearPhysicians = { type: actionTypes.clearPhysicians };

export const fetchPhysician = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchPhysician });

        const http = new HttpService();

        return http.getData(`resources/physicians/${id}`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(physician => {
                dispatch(receivePhysician(physician));
            })
    }
}

export const searchPhysician = (searchParams) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.searchPhysician });

        const http = new HttpService();

        return http.getData(`resource/physicians`, 'user-token', searchParams)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();

            })
            .then(physicians => {
                dispatch(receivePhysicians(physicians.data));
            })
    }
}