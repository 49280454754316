import React, { useState } from 'react';
import { Button, Divider, LinearProgress, TextField, Typography } from '@mui/material';
import { selectProfile } from '../selectors/profile';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/profile';
import { useNavigate } from 'react-router-dom';


const Profile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(selectProfile);
    const [credentials, setCredentials] = useState({ newPassword: '', newPasswordConfirmation: '', oldPassword: '' });

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    return (

        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Perfil de usuario</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em', padding: '3em' }}>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography>{profile.data?.name}</Typography>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography>{profile.data?.email}</Typography>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography>{profile.data?.role?.description}</Typography>
                    </div>
                    <div style={{ marginBottom: '3em' }}>
                        <Button size="small" variant="contained" fullWidth onClick={handleLogout}>Log out</Button>
                    </div>


                    {
                        /*
                            <div style={{ marginBottom: '3em' }}>
                                <Divider />
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <TextField size="small" variant="outlined" placeholder="Password" fullWidth value={credentials.oldPassword} onChange={(event) => setCredentials({ ...credentials, oldPassword: event.target.value })} />
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <TextField size="small" type="password" variant="outlined" placeholder="Nuevo password" fullWidth value={credentials.newPaassword} onChange={(event) => setCredentials({ ...credentials, newPaassword: event.target.value })} />
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <TextField size="small" variant="outlined" placeholder="Confirmar nuevo password" fullWidth value={credentials.newPasswordConfirmation} onChange={(event) => setCredentials({ ...credentials, newPasswordConfirmation: event.target.value })} />
                            </div>
                            <div style={{ marginBottom: '1em' }}>
                                <Button size="small" variant="contained" fullWidth >Cambiar</Button>
                            </div>
                            <div style={{ height: '1em' }}>
                                {
                                    profile.loading && <LinearProgress color="primary" />
                                }
                            </div>
                        */
                    }
                </div>
            </div>
        </div >
    );
};

export default Profile;