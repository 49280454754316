export const isPhysicianRegistryEnabled = (state) => {
    
    if (state.settings.some(setting=>setting.key === 'allowPhysicianRegistry'))
    {
        return state.settings.filter(setting=>setting.key === 'allowPhysicianRegistry')[0].value === 'true';
    }

    return false;
}

export const isAllowWhatsappPatientRegistrationEnabled = (state) => {
    
    if (state.settings.some(setting=>setting.key === 'allowWhatsappPatientRegistration'))
    {
        return state.settings.filter(setting=>setting.key === 'allowWhatsappPatientRegistration')[0].value === 'true';
    }

    return false;
}