import { combineReducers } from 'redux';
import orders from './orders.js';
import patient from './patient.js';
import patients from './patients.js';
import physicians from './physicians.js';
import order from './order.js';
import profile from './profile.js';
import patientResults from './patientResults.js';
import results from './results.js';
import catalogs from './catalogs.js';
import users from './users.js';
import { signup } from './signup.js';
import forgot from './forgot.js';
import useLogs from './useLogs.js';
import notificationLogs from './notificationLogs.js';
import dataware from './dataware.js';
import intervals from './intervals.js';
import settings from './settings.js';
import verification from './verification.js';
import documentToken from './documentToken.js';
import whatsapp from './whatsapp.js';

export default combineReducers({orders, patient, order, results, patientResults, profile, patients, signup, catalogs, users,forgot, useLogs,dataware,intervals,settings,physicians,verification,documentToken,whatsapp,notificationLogs});