import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchOrders: "FETCH_DW_ORDERS",
    receiveOrders: "RECEIVE_DW_ORDERS",
    clearOrders : "CLEAR_DW_ORDERS",
    fetchUsers: "FETCH_DW_USERS",
    receiveUsers: "RECEIVE_DW_USERS",
    clearUsers : "CLEAR_DW_USERS"
};

const receiveOrders = (orders) => ({ type: actionTypes.receiveOrders, orders });
const receiveUsers = (users) => ({ type: actionTypes.receiveUsers, users });

export const clearOrders = { type: actionTypes.clearOrders };
export const clearUsers = { type: actionTypes.clearUsers };

export const fetchOrders = (from,to) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrders });

        const http = new HttpService();

        return http.getData(`dataware/orders?from=${from}&to=${to}`)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(orders => {
                dispatch(receiveOrders(orders));
            })
    }
}

export const fetchUsers = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrders });

        const http = new HttpService();

        return http.getData('dataware/users')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(users => {
                dispatch(receiveUsers(users));
            })
    }
}
