import React, { useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, useMediaQuery } from '@mui/material';


const OriginsDialog = ({ open, data, origins, close }) => {

    const [newData, setNewData] = useState(data);
    const originIsSelected = (origin) => {
        return newData.filter(item => item.id === origin.id).length>0;
    }

    const handleChange = (origin) => {
        if (originIsSelected(origin))
            setNewData(newData.filter(o => o.id !== origin.id))
        else
            setNewData([origin, ...newData]);
    }

    return (
        <Dialog
            open={open}
            fullWidth
            PaperProps={{ style: { padding: '3em' } }}>
            <DialogTitle id="station-dialog-title">Centros</DialogTitle>
            <DialogContent>
                <FormGroup>
                    {
                        origins.map(
                            origin => <FormControlLabel control={<Checkbox checked={originIsSelected(origin)} onChange={() => handleChange(origin)} />} label={origin.name}/>
                        )
                    }
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={() => close(newData)} >ACEPTAR</Button>
                <Button color="secondary" variant="contained" onClick={() => close(data)}>CANCELAR</Button>
            </DialogActions>
        </Dialog>);
};

export default OriginsDialog;


