import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { Refresh } from "@mui/icons-material";
import { Button, Grid, LinearProgress, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Area, AreaChart, CartesianGrid,  Cell,  Pie,  PieChart,  ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { fetchUseLogs } from "../actions/useLogs";
import { selectUseLogs } from "../selectors/useLogs";
import { fetchOrders, fetchUsers } from "../actions/dataware";
import { selectDataware } from "../selectors/dataware";
import { fetchMonthInterval } from "../actions/intervals";
import { selectIntervals } from "../selectors/intervals";
import { selectNotificationLogs } from "../selectors/notificationLogs";
import { fetchNotificationLogs } from "../actions/notificationLogs";

const getLabel = (type) => {
if (type === 'patientsCreated') {
    return "Nuevos";
}
if (type === 'patientsRegistered') {
    return "Registrados";
}
if (type === 'physicians') {
    return "Medicos";
}
return '';
};

const getResultLabel = (type) => {
if (type === 'viewed') {
    return "Vistos";
}
if (type === 'downloaded') {
    return "Descargados";
}
if (type === 'clinical') {
    return "Clinica";
    }
    return '';
};

const getNotificationLabel = (type) => {
    if (type === 'registry') {
        return "Registro";
    }
    if (type === 'newOrder') {
        return "Nueva orden";
    }
    if (type === 'resultsAvailable') {
        return "Resultados";
    }
    if (type === 'verification') {
        return "Verificaciones";
    }
    if (type === 'passwordRecovery') {
        return "Recuperacion password";
    }
    if (type === 'admin') {
        return "Administrativo";
    }

    return '';
};

const CustomTooltip = ({ active, payload, label },interval) => {
    if (active && payload && payload.length) {
    return (<Grid container direction="column" style={{backgroundColor:'white', padding:'0.5em',opacity:'0.8'}}>
            <Typography><Box fontWeight={800}>{`${label} ${interval?.name}`}</Box></Typography>
            {
                payload.map(item =>  <Grid item container>
                    <Typography>{getLabel(item.name)}</Typography>
                    <Grid item xs/>
                    <Typography>{item.value}</Typography>
                </Grid>)
            }
        </Grid>
    );
    }
    return null;
};

const CustomResultTooltip = ({ active, payload, label },interval) => {
    if (active && payload && payload.length) {
    return (<Grid container direction="column" style={{backgroundColor:'white', padding:'0.5em',opacity:'0.8'}}>
        <Typography>
            <Box fontWeight={800}>{`${label} ${interval?.name}`}</Box>
        </Typography>
        {
            payload.map(
                item =>  
                    <Grid item container>
                        <div style={{width:'10em'}}>
                            <Typography>{getResultLabel(item.name)}</Typography>
                        </div>
                        <Typography>{item.value}</Typography>
                    </Grid>
            )
        }
    </Grid>
        );
    }
return null;
};


const CustomNotificationTooltip = ({ active, payload, label },interval) => {
    if (active && payload && payload.length) {
        return (<Grid container direction="column" style={{backgroundColor:'white', padding:'0.5em',opacity:'0.8'}}>
            <Typography>
                <Box fontWeight={800}>{`${label} ${interval?.name}`}</Box>
            </Typography>
            {
                payload.map(
                    item =>  
                        <Grid item container>
                            
                            <div style={{width:'10em'}}>
                                <Typography>{getNotificationLabel(item.name)}</Typography>
                            </div>
                            <Typography>{item.value}</Typography>
                        </Grid>
                )
            }
        </Grid>
        );
    }
return null;
};

const CustomOrderTooltip = ({ active, payload, label },interval) => {

    if (active && payload && payload.length) {
        return (<Grid container direction="column" style={{backgroundColor:'white', padding:'0.5em',opacity:'0.8'}}>
            <Typography>
                <Box fontWeight={800}>{`${label} ${interval?.name}`}</Box>
            </Typography>
            {
                
                payload.map(
                    item =>  
                        <Grid item container>
                            <Typography>Ordenes</Typography>
                        <Grid item xs/>
                            <Typography>{item.value}</Typography>
                        </Grid>)
            }
        </Grid>
        );
    }

    return null;
};

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const Board = () => {

    const dispatch = useDispatch();
    const [interval, setInterval] = useState(undefined);
    const {months} = useSelector(selectIntervals);
    const {loading:loadingUseLogs,data:dataUseLogs} = useSelector(selectUseLogs);
    const {loading : loadingNotificationLogs, data: dataNotificationLogs} = useSelector(selectNotificationLogs);

    const dataware = useSelector(selectDataware);
    
    useEffect(() => {
        dispatch(fetchMonthInterval(6));
    }, []);

    useEffect(() => {
        if (months.length){
            setInterval(months[0]);
            handleRefresh(months[0]);
        }
    }, [months]);

    const handleRefresh = (month) => {

        dispatch(fetchUseLogs(month.from,month.to))
        dispatch(fetchNotificationLogs(month.from,month.to))
        dispatch(fetchOrders(month.from, month.to));
        dispatch(fetchUsers());
    }

    const handleChange = (event) =>{

        setInterval(event.target.value);
        handleRefresh(event.target.value);
    };

    
    return(
        <div style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '20em', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft:'3em', paddingRight:'3em' }}>
                <Typography textAlign="center" variant="h5" color="text.black" >
                    <Box fontWeight={750}>Dashboard</Box>
                </Typography>
                <div style={{flexGrow:1}}></div>

                <TextField
                    placeholder="Periodo"
                    label="Seleccionar periodo"
                    select
                    style={{width:'20em'}}
                    value={interval || ''}
                    onChange={handleChange}
                    size="small">
                        {
                            months?.map(month => (
                                <MenuItem key={month.name} value={month}>
                                    {month.name}
                                </MenuItem>
                            ))
                        }
                </TextField>

                <Button disabled={!interval} onClick={()=>handleRefresh(interval)}>
                    <Refresh/>
                </Button>                    

            </div>
            <div style={{ marginBottom: '1em' }}>
                {
                    loadingUseLogs && loadingNotificationLogs && 
                    <LinearProgress />
                }
            </div>

            <div style={{ overflowY: 'auto', marginLeft: '3em', marginRight: '3em' }}>
                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>

                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                        <div style={{minWidth:'15em'}}>
                            <Typography variant="h5" textAlign="left">Usuarios</Typography>
                            <Typography variant="body" style={{marginBottom:'1em'}}>Total</Typography>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                                    <Typography>Registrados</Typography>
                                </div>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                                    <Typography>No registrados</Typography>
                                </div>
                                <div style={{display:'flex', alignItems:'center', marginBottom:'1em'}}>
                                    <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#F35576', marginRight:'1em'}}></div>
                                    <Typography>Medicos</Typography>
                                </div>
                            <Typography variant="h4">{dataware?.users?.total}</Typography>
                        </div>
                        <div style={{flexGrow:1, padding:'2em'}}>
                            <ResponsiveContainer width={300} height={300}>
                                <PieChart>
                                    <Pie
                                        data={dataware?.users?.data}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="count"
                                    >

                                        <Cell key={"registered"} fill={"#54DDC1"}/>
                                        <Cell key={"notRegistered"} fill={"#0E4B6C"}/>
                                        <Cell key={"physicians"} fill={"#F35576"}/>
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>


                    <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                        <div style={{minWidth:'15em'}}>
                            <Typography variant="h5" textAlign="left">Registros</Typography>
                            <Typography variant="body" style={{marginBottom:'1em'}}>Total</Typography>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                                    <Typography>Whatsapp</Typography>
                                </div>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                                    <Typography>Email</Typography>
                                </div>
                            <Typography variant="h4">{dataware?.users?.data?.filter(v=>v.type==='registered')[0]?.count}</Typography>
                        </div>
                        <div style={{flexGrow:1, padding:'2em'}}>
                            <ResponsiveContainer width={300} height={300}>
                                <PieChart>
                                    <Pie
                                        data={dataware?.users?.data?.filter(v=>v.type ==='whatsappRegistered' || v.type ==='emailregistered')}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={renderCustomizedLabel}
                                        outerRadius={80}
                                        fill="#8884d8"
                                        dataKey="count"
                                    >
                                        <Cell key={"whatsappRegistered"} fill={"#54DDC1"}/>
                                        <Cell key={"emailRegistered"} fill={"#0E4B6C"}/>
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>


                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{minWidth:'15em'}}>
                        <Typography variant="h5" textAlign="left">Ordenes</Typography>
                        <Typography variant="body" style={{marginBottom:'1em'}}>Total</Typography>
                        <Typography variant="h4">{dataware?.orders?.total}</Typography>
                    </div>
                    <div style={{flexGrow:1, padding:'2em'}}>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <AreaChart
                                data={dataware?.orders?.data}
                                margin={{left:-30}}
                                >
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0E4B6C" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0E4B6C" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="5 5" />
                                <XAxis dataKey="day" />
                                <YAxis/>
                                <Tooltip content={(params)=>CustomOrderTooltip(params,interval)}/>
                                <Area type="monotone" dataKey="orders" stroke="#0E4B6C" fillOpacity={1} fill="url(#colorUv)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{minWidth:'15em'}}>
                        <Typography variant="h5" textAlign="left">Usuarios</Typography>
                        <Typography variant="body" style={{marginBottom:'1em'}}>En el tiempo</Typography>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                            <Typography>Nuevos</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center', }}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                            <Typography>Registrados</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginBottom:'1em'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#F35576', marginRight:'1em'}}></div>
                            <Typography>Medicos</Typography>
                        </div>
                        <Typography variant="h4">{dataUseLogs?.signup?.total}</Typography>
                    </div>
                    <div style={{flexGrow:1, padding:'2em'}}>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <AreaChart
                                data={dataUseLogs?.signup?.data}
                                margin={{left:-30}}
                                >
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0E4B6C" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0E4B6C" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#54DDC1" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#54DDC1" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPhysicians" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F35576" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#F35576" stopOpacity={0}/>
                                    </linearGradient>

                                </defs>
                                <CartesianGrid strokeDasharray="5 5" />
                                <XAxis dataKey="day" />
                                <YAxis/>
                                <Tooltip content={(params)=>CustomTooltip(params,interval)}/>
                                <Area type="monotone" dataKey="patientsCreated" stroke="#0E4B6C" fillOpacity={1} fill="url(#colorUv)" />
                                <Area type="monotone" dataKey="patientsRegistered" stroke="#54DDC1" fillOpacity={1} fill="url(#colorPv)" />
                                <Area type="monotone" dataKey="physicians" stroke="#F35576" fillOpacity={1} fill="url(#colorPhysician)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', minWidth:'15em'}}>
                        <Typography variant="h5" textAlign="left">Resultados</Typography>
                        <Typography variant="body" textAlign="left" style={{marginBottom:'1em'}}>Pacientes</Typography>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                            <Typography>Vistos</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center', marginBottom:'1em'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                            <Typography>Descargados</Typography>
                        </div>
                        <Typography variant="h4">{dataUseLogs?.resultsByPatient?.total}</Typography>
                    </div>
                    <div style={{flexGrow:1, padding:'2em'}}>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <AreaChart
                                data={dataUseLogs?.resultsByPatient?.data}
                                margin={{left:-30}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0E4B6C" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0E4B6C" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#54DDC1" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#54DDC1" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="5 5" />
                                <XAxis dataKey="day" />
                                <YAxis/>
                                <Tooltip content={(params)=>CustomResultTooltip(params,interval)}/>
                                <Area type="monotone" dataKey="viewed" stroke="#0E4B6C" fillOpacity={1} fill="url(#colorUv)" />
                                <Area type="monotone" dataKey="downloaded" stroke="#54DDC1" fillOpacity={1} fill="url(#colorPv)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', minWidth:'15em'}}>
                        <Typography variant="h5" textAlign="left">Resultados</Typography>
                        <Typography variant="body" textAlign="left" style={{marginBottom:'1em'}}>Medicos</Typography>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                            <Typography>Vistos</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                            <Typography>Descargados</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center',marginBottom:'1em'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#F35576', marginRight:'1em'}}></div>
                            <Typography>Vista clinica</Typography>
                        </div>
                        <Typography variant="h4">{dataUseLogs?.resultsByPhysician?.total}</Typography>
                        
                    </div>
                    <div style={{flexGrow:1, padding:'2em'}}>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <AreaChart
                                data={dataUseLogs?.resultsByPhysician?.data}
                                margin={{left:-30}}
                                >
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0E4B6C" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0E4B6C" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#54DDC1" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#54DDC1" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="colorClinical" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F35576" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#F35576" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="5 5" />
                                <XAxis dataKey="day" />
                                <YAxis/>
                                <Tooltip content={(params)=>CustomResultTooltip(params,interval)}/>
                                <Area type="monotone" dataKey="viewed" stroke="#0E4B6C" fillOpacity={1} fill="url(#colorUv)" />
                                <Area type="monotone" dataKey="downloaded" stroke="#54DDC1" fillOpacity={1} fill="url(#colorPv)" />
                                <Area type="monotone" dataKey="clinical" stroke="#F35576" fillOpacity={1} fill="url(#colorClinical)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                    
                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', minWidth:'15em'}}>
                        <Typography variant="h5" textAlign="left">Mensajeria enviada</Typography>
                        <Typography variant="body" textAlign="left" style={{marginBottom:'1em'}}>Whatsapp</Typography>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                            <Typography>Registros</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                            <Typography>Ordenes</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center',marginBottom:'1em'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#F35576', marginRight:'1em'}}></div>
                            <Typography>Resultados</Typography>
                        </div>
                        <Typography variant="h4">{dataNotificationLogs?.whatsappByType?.total}</Typography>
                        
                    </div>

                    <div style={{flexGrow:1, padding:'2em'}}>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <AreaChart
                                data={dataNotificationLogs?.whatsappByType?.data}
                                margin={{left:-30}}
                                >
                                <defs>
                                    <linearGradient id="registry" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0E4B6C" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0E4B6C" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="newOrder" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#54DDC1" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#54DDC1" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="resultsAvailable" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F35576" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#F35576" stopOpacity={0}/>
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="5 5" />
                                <XAxis dataKey="day" />
                                <YAxis/>
                                <Tooltip content={(params)=>CustomNotificationTooltip(params,interval)}/>
                                <Area type="monotone" dataKey="registry" stroke="#0E4B6C" fillOpacity={1} fill="url(#registry)" />
                                <Area type="monotone" dataKey="newOrder" stroke="#54DDC1" fillOpacity={1} fill="url(#newOrder)" />
                                <Area type="monotone" dataKey="resultsAvailable" stroke="#F35576" fillOpacity={1} fill="url(#resultsAvailable)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>

                <div style={{display:'flex', alignItems:'center'}}>
                    <div style={{display:'flex', flexDirection:'column', minWidth:'15em'}}>
                        <Typography variant="h5" textAlign="left">Mensajeria enviada</Typography>
                        <Typography variant="body" textAlign="left" style={{marginBottom:'1em'}}>Email</Typography>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#0E4B6C', marginRight:'1em'}}></div>
                            <Typography>Registros</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#54DDC1', marginRight:'1em'}}></div>
                            <Typography>Ordenes</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#F35576', marginRight:'1em'}}></div>
                            <Typography>Resultados</Typography>
                        </div>
                        <div style={{display:'flex', alignItems:'center',marginBottom:'1em'}}>
                            <div style={{width:'0.8em', height:'0.8em', backgroundColor:'#B1A112', marginRight:'1em'}}></div>
                            <Typography>Administrativo</Typography>
                        </div>
                        <Typography variant="h4">{dataNotificationLogs?.emailByType?.total}</Typography>
                        
                    </div>

                    <div style={{flexGrow:1, padding:'2em'}}>
                        <ResponsiveContainer width={'100%'} height={300}>
                            <AreaChart
                                data={dataNotificationLogs?.emailByType?.data}
                                margin={{left:-30}}
                                >
                                <defs>
                                    <linearGradient id="registry" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#0E4B6C" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#0E4B6C" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="newOrder" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#54DDC1" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#54DDC1" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="resultsAvailable" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#F35576" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#F35576" stopOpacity={0}/>
                                    </linearGradient>
                                    <linearGradient id="admin" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#B1A112" stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor="#B1A112" stopOpacity={0}/>
                                    </linearGradient>

                                </defs>
                                <CartesianGrid strokeDasharray="5 5" />
                                <XAxis dataKey="day" />
                                <YAxis/>
                                <Tooltip content={(params)=>CustomNotificationTooltip(params,interval)}/>
                                <Area type="monotone" dataKey="registry" stroke="#0E4B6C" fillOpacity={1} fill="url(#registry)" />
                                <Area type="monotone" dataKey="newOrder" stroke="#54DDC1" fillOpacity={1} fill="url(#newOrder)" />
                                <Area type="monotone" dataKey="resultsAvailable" stroke="#F35576" fillOpacity={1} fill="url(#resultsAvailable)" />
                                <Area type="monotone" dataKey="admin" stroke="#B1A112" fillOpacity={1} fill="url(#admin)" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>)
  };

export default Board;