import { combineReducers } from 'redux';
import { actionTypes } from '../actions/profile';


export const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.setProfile:
            return false
        case actionTypes.setTokens:
            return false
        case actionTypes.loginFailed:
            return false
        case actionTypes.tryLogin:
            return true
        case actionTypes.fetchProfile:
            return true
        default:
            return state
    }
}

export const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.setProfile:
            return { ...state, ...action.profile, loginSuccessful: true }
        case actionTypes.setTokens:
            return { ...state, ...action.tokens }
        case actionTypes.clearProfile:
            return {}
        case actionTypes.tryLogin:
            return {}
        case actionTypes.loginFailed:
            return { loginFailed: true, error:action.error }
        default:
            return state
    }
}

export default combineReducers({ loading, data });