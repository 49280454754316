export const selectUseLogs = (state) => {
    return state.useLogs;
}

export const selectUserActivity = (state) => {
    return state.useLogs.data.filter(log => log.type === 'userCreated' || log.type === 'userRegistered');
}

export const selectPatientClinicalActivity = (state) => {
    return state.useLogs.data.filter(log => log.type === 'resultsViewed' || log.type === 'reportDownloaded' || log.type === 'clinicalViewed');
}
