import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';


const OrderStatus = ({order, role}) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const orderStatus = {
        new: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: 'PENDIENTE', mobileDescription: 'P' },
        partial: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.available, borderRadius: 7 }, description: 'PARCIAL', mobileDescription: 'PR' },
        posted: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.available, borderRadius: 7 }, description: 'COMPLETO',mobileDescription: 'C' },
        cancelled: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.cancelled, borderRadius: 7 }, description: 'RECITADO', mobileDescription: 'R' }
    }

    const status = role === 'patient' ? order?.released ? order?.status : 'new' : order?.status;

    return (
        <Typography 
            style={orderStatus[status].style}
            align="center"
            variant="body2">
            <Box fontWeight={'600'}>{matchMD ? orderStatus[status].mobileDescription: orderStatus[status].description}</Box>
        </Typography >

    );
};

export default OrderStatus;