import { Switch } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { changeUserStatus } from "../actions/users";

const UserSwitch = ({id, enabled, role, onChange}) => {

    const dispatch = useDispatch();

    const handleChange = (id, enabled) => {
        onChange(enabled);
        dispatch(changeUserStatus(id,enabled));
    }

    return <Switch
        color="secondary"
        checked={enabled}
        disabled={role?.code === 'patient'}
        onChange={()=>handleChange(id, !enabled)}/>
}

export default UserSwitch;
