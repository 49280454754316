import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { changeUserOrigins } from "../actions/users";
import { selectOrigins } from "../selectors/catalogs";
import OriginsDialog from "../views/OriginsDialog";


const OriginsHandler = ({user, textFieldProps}) => {

    const dispatch = useDispatch();
    const [openOriginsDialog, toggleOriginsDialog] = useState(false);
    const origins = useSelector(selectOrigins);

    const handleClick = () => {
        toggleOriginsDialog(true);
    }

    const translateOrigins = (origins) => {
        return origins?.length > 0 ? origins.map(o => o.code) :''
    };

    const handleCloseOriginsDialog = (data) => {
        dispatch(changeUserOrigins(user.id, data));
        toggleOriginsDialog(false);
    };

    return <Fragment>
            <TextField
                id="origins"
                size='small'
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true,endAdornment: <InputAdornment position="end"><IconButton onClick={() => handleClick()}><Visibility /></IconButton></InputAdornment> }}
                value={translateOrigins(user?.origins)}
                {...textFieldProps}
            />
            {
                openOriginsDialog &&
                <OriginsDialog open={openOriginsDialog} origins={origins} data={user?.origins} close={handleCloseOriginsDialog} />
            }
        </Fragment>
}

export default OriginsHandler;