import React, { useState } from 'react';
import { Button, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { validateEmail } from '../actions/forgot';
import { selectForgotData } from '../selectors/forgot';


const ForgotPassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get('email') || '');
    
    const {status,verificationToken} = useSelector(selectForgotData);

    useEffect(() => {
        status.email.valid && navigate(`/forgot/verification/${verificationToken}`)
    }, [status]);

    const handleContinue = () => {
        dispatch(validateEmail(email));
    };

    const validForm = email != '' ;
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Recuperar contraseña</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em' }}>

                    <div style={{ marginBottom: '1em' }}>
                        <TextField 
                            id="email"
                            size="small" 
                            variant="outlined"
                            placeholder="Email o Whatsapp"
                            fullWidth
                            value={email}
                            onChange={(event) => setEmail(event.target.value)} />
                    </div>
                    
                    <div>
                        <Typography>{status.email.error}</Typography>
                    </div>


                    <div style={{ marginBottom: '1em' }}>
                        <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            disabled={!validForm}
                            onClick={() => handleContinue()}>Continuar</Button>
                    </div>

                    {
                        status.email.validating &&
                        <div style={{ height: '1em',marginTop:'1em', marginBottom:'1em' }}>
                            <LinearProgress color="primary" />
                        </div>
                    }

                </div>
            </div>
        </div >
    );
};

export default ForgotPassword;