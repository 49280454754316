import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LinearProgress, TextField, Typography,Button } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { clearVerification, fetchVerification, fetchVerificationDocument } from "../actions/verification";
import { selectVerification } from "../selectors/verification";

const DocumentValidator = () => {

    const {order,key} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVerification(order,key));

        return () => {
            dispatch(clearVerification());
        }
    }, []);

    const {loading, data} = useSelector(selectVerification);

    const handleDownload = (order,key) => {
        dispatch(fetchVerificationDocument(order,key));
    };

    return (        
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black">
                    <Box fontWeight={750}>Verificación de informe</Box>
                </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em', padding: '3em' }}>
                    <div style={{ marginBottom: '1em' }}>
                        <Typography textAlign="center" variant="body1" color="text.black">
                            <Box fontWeight={350}>El informe escaneado pertence al paciente</Box>
                        </Typography>
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField id="patient" size="small" variant="outlined" label="Paciente" fullWidth value={data?.patient ? `${data.patient.last_name} ${data.patient.first_name} ` : ''} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField id="order" size="small" variant="outlined" label="Orden" fullWidth value={data?.order ? data.order.number : ''} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Button size="small" variant="contained" fullWidth onClick={() => handleDownload(data?.order?.number, data?.document?.key)}>Descargar</Button>
                    </div>
                    <div style={{ height: '1em' }}>
                        {
                            loading && <LinearProgress color="primary" />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentValidator;