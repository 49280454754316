import { combineReducers } from 'redux';
import { actionTypes } from '../actions/results';
import { actionTypes as profileActions } from '../actions/profile';


const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchPatientResults:
            return true
        case actionTypes.receivePatientResults:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receivePatientResults:
            return action.results
        case actionTypes.clearPatientResults:
            return []
        case profileActions.clearProfile:
            return []
        default:
            return state

    }
}

export default combineReducers({ loading, data });