import { actionTypes } from '../actions/signup';


const signupData = {
    tokenStatus: { valid: false, validating: false },
    userStatus: { valid: false, validating: false, error: '' },
    passwordStatus: { created: false, creating: false, error: '' },
    physicianUserStatus : {created:false, creating:false, error:''},
    emailStatus : {validating:false, valid:false, error:'',code:''}
}

export const signup = (state = signupData, action) => {
    switch (action.type) {
        case actionTypes.setTokenStatus:
            return { ...state, tokenStatus: { ...action.tokenStatus } }
        case actionTypes.setUserStatus:
            return { ...state, userStatus: { ...action.userStatus } }
        case actionTypes.setPasswordStatus:
            return { ...state, passwordStatus: { ...action.passwordStatus } }
        case actionTypes.createPhysician:
            return { ...state, physicianUserStatus: { created:false, creating:true, error:'' } }
        case actionTypes.confirmPhysician:
            return { ...state, physicianUserStatus: { ...action.physicianUserStatus} }
        case actionTypes.cleanPhysician:
            return { ...state, physicianUserStatus: { ...signupData.physicianUserStatus} }
        case actionTypes.validateEmail:
            return { ...state, emailStatus:{validating:false, valid:true,error:'',code:'' }}
        case actionTypes.clearEmail:
            return { ...state, emailStatus:{validating:false, valid:false,error:'',code:'' }}
        case actionTypes.validatingEmail:
            return { ...state, emailStatus:{validating:true, valid:false,error:'',code:'' }}
        case actionTypes.rejectEmail:
            return { ...state, emailStatus:{validating:false, valid:false,error:action.error,code:action.code }}
        default:
            return state
    }
}

