import { combineReducers } from 'redux';
import { actionTypes } from '../actions/catalogs';

const origins = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchOrigins:
            return []
        case actionTypes.receiveOrigins:
            return action.origins
        default:
            return state
    }
}

export default combineReducers({ origins });

