import { combineReducers } from 'redux';
import { actionTypes } from '../actions/users';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchUsers:
            return true
        case actionTypes.receiveUsers:
            return false
        case actionTypes.fetchUser:
            return true
        case actionTypes.receiveUser:
            return false
        default
        :
        return state
    }
}

const saving = (state = false, action) => {
    switch (action.type) {
        case actionTypes.updateUser:
            return true
        case actionTypes.receiveUser:
            return false
        case actionTypes.setError:
            return false
        default:
            return state
    }
}

const saved = (state = false, action) => {
    switch (action.type) {
        case actionTypes.clearUser:
            return false
        case actionTypes.fetchUser:
            return false
        case actionTypes.updateUser:
            return false
        case actionTypes.setSaved:
            return true
        default:
            return state
    }
}

const deleted = (state = false, action) => {
    switch (action.type) {
        case actionTypes.clearUser:
            return false
        case actionTypes.fetchUser:
            return false
        case actionTypes.setDeleted:
            return true
        default:
            return state
    }
}

const error = (state = "", action) => {
    switch (action.type) {
        case actionTypes.updateUser:
            return ""
        case actionTypes.receiveUser:
            return ""
        case actionTypes.setError:
            return action.error
        default:
            return state
    }
}

const current = (state = {},action) =>{
    switch (action.type){
        case actionTypes.fetchUsers:
            return {}
        case actionTypes.clearUser:
            return {}
        case actionTypes.setUser:
            return action.user
        case actionTypes.receiveUser:
            return action.user
        default:
            return state
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchUsers:
            return {}
        case actionTypes.receiveUsers:
            return action.users
        case actionTypes.receiveUser:
            return {...state, data: [...state.data.map(user => user.id !== action.user.id ? user : action.user)]}
        default:
        return state
    }
}


export default combineReducers({ loading, data, current,saving,saved,error});

