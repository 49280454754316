export const selectResults = (state) => {
    return state.results;
}

export const selectPatientResults = (state) => {
    return state.patientResults;
}

export const selectOrdersFromPatientResults = (state) => {
    return state.patientResults.data.map(order => ({ id: order.id, number: order.number, date: order.date, status: order.status }))
}

export const selectUniqueTestsFromPatientResults = (state) => {

    let tests = [];

    state.patientResults.data.forEach(order =>
        order.studies.forEach(study =>
            study.tests.forEach(test => tests.push({ test_type_code: test.test_type_code, test_type_name: test.test_type_name }))));


    const uniqueTests = tests.reduce((acc, current) => {
        const x = acc.find(item => item.test_type_code === current.test_type_code);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    return uniqueTests;
}

export const selectAllTestsFromPatientResults = (state) => {

    let tests = [];

    state.patientResults.data.forEach(order =>
        order.studies.forEach(study =>
            study.tests.forEach(test => tests.push(test))));



    return tests;
}