import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, LinearProgress, TextField, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from '../selectors/profile';
import { login, user } from '../actions/profile';
import { Box } from '@mui/system';
import Cookies from 'js-cookie';
import { Stop, StopCircle, Warning } from '@mui/icons-material';


const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profile = useSelector(selectProfile);
    const [credentials, setCredentials] = useState({ username: '', password: '', remeber: false });
    const token = profile?.data?.access_token || Cookies.get('token');

    useEffect(() => {

        if (token) {
            if (profile.data && profile.data.loginSuccessful) {

                if (profile.data.role?.code === 'physician' || profile.data.role?.code === 'admin') {
                    navigate(`/patient-search`);
                }
                else if (profile.data.role?.code === 'patient') {
                    navigate(`/orders/${profile.data.patients[0].id}`);
                }
            }
        }

    }, [profile.data, token]);


    useEffect(() => {
        if (token && !profile?.data?.id) {
            dispatch(user());
        }
    }, [token]);

    const handleLogin = () => {
        dispatch(login(credentials));
    };

    const handleRegister = () => {
        navigate('signup/physician');
    }
    return (

        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Bienvenido a Healthbook</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '20em', padding: '3em' }}>

                    <div style={{ marginBottom: '1em' }}>
                        <TextField size="small" variant="outlined" placeholder="Email o Whatsapp" fullWidth value={credentials.username} onChange={(event) => setCredentials({ ...credentials, username: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField size="small" type="password" variant="outlined" placeholder="Password" fullWidth value={credentials.password} onChange={(event) => setCredentials({ ...credentials, password: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <FormControlLabel
                            label="Mantener sesion iniciada"
                            control={<Checkbox checked={credentials.remember} onChange={(event) => setCredentials({ ...credentials, remember: event.target.checked })} />}
                        />
                    </div>
                    <div style={{ marginBottom: '2em' }}>
                        <Button size="small" variant="contained" fullWidth onClick={() => handleLogin()}>Login</Button>
                    </div>
                    <div style={{ textAlign:'center',marginBottom: '2em', maxWidth:'20em', }}>
                        {
                            profile?.data?.loginFailed && 
                            <div>
                                <Warning color='primary'></Warning>
                                <Typography color='primary' textAlign={'center'} variant="body1">{profile?.data?.error}</Typography>
                            </div>
                        }
                    </div>
                    <div style={{ textAlign:'center',marginBottom: '1em'}}>
                        <Link to="forgot" style={{textDecoration:'none'}}>
                            <Button size="small" variant="outlined" fullWidth>Olvide la contraseña</ Button>
                        </Link>
                    </div>
                    <div style={{ textAlign:'center',marginBottom: '1em'}}>
                        <Link to="register" style={{textDecoration:'none'}}>
                            <Button size="small" variant="outlined" fullWidth>Aun no registre mi usuario</ Button>
                        </Link>
                    </div>
                    <div style={{ height: '1em' }}>
                        {
                            profile.loading && <LinearProgress color="primary" />
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Login;