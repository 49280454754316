import { createTheme } from '@mui/material/styles';

export const arencoPantone = {
    darkBlue: "#052443",
    mediumBlue: "#003152",
    lightBlue: "#0e4b6c",
    green: '#b1ded3',
    gray: '#ECF1F4',
    black: '#282828',

}

export default createTheme({
    palette: {
        arenco: {
            darkBlue: arencoPantone.darkBlue,
            mediumBlue: arencoPantone.mediumBlue,
            lightBlue: arencoPantone.lightBlue,
            green: arencoPantone.green,
        },

        primary: {
            main: arencoPantone.lightBlue,
        },

        secondary: {
            main: arencoPantone.green,
        },

        pending: '#b71c1c',

        red: {
            main: '#b71c1c'
        },

        gray: {
            main: arencoPantone.gray,
        },

        text: {
            black: arencoPantone.black,
            blue: arencoPantone.mediumBlue,
            
        },

        backgrounds: {
            gray: '#f4f4f4'
        },

        status: {
            cancelled: '#ee7a7d',
            pending: '#4d57a3',
            available: '#3daaa8',
        }

    },
    typography: {

        fontFamily: 'Roboto',
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,

        title: {
            fontWeight: '400',
            fontSize: '4rem',
            fontFamily: 'Roboto'
        },

        tab: {
            textTransform: 'none',
            fontWeight: '700',
            fontSize: '1rem',
        }
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize: '1rem',
            },
            body2: {
                fontSize: '0.8rem',
            }
        },
        MuiSlider:
            { backgroundColor: 'green' }
    },
    props: {
        MuiButtonBase: {
            disableRipple: true
        },
        MuiIconButton: {
            disableRipple: true,
            disableFocusRipple: true,
        },
    },
    styles: {
        iconButtons: {

            "&:hover": {
                backgroundColor: "transparent"
            }

        }
    }
});