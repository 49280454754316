import React from 'react';

import { AppBar, Avatar, IconButton, Toolbar, useMediaQuery, useTheme, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { selectProfile } from '../selectors/profile';
import { Link } from 'react-router-dom';
import { getInitials } from '../helpers/nameInitials';
import cebac_logo from './../assets/cebac_logo_horizontal.png';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import { Dashboard, WhatsApp } from '@mui/icons-material';
import { isAllowWhatsappPatientRegistrationEnabled } from '../selectors/settings';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appBarRight: {
        float: 'flex',
    },
    appBar: {
        zIndex: 1100,
        position: 'absolute',
    },
    hide: {
        display: 'none',
    },
}));

export default function TopBar({ onOpenMenu, onLogout }) {

    const classes = useStyles();
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));
    const profile = useSelector(selectProfile);
    const whatsappRegistration = useSelector(isAllowWhatsappPatientRegistrationEnabled);
    const initials = getInitials(profile.data?.name);

    return (
        <AppBar position="fixed">
            <Toolbar>
                <img src={cebac_logo} style={{maxHeight:'4em'}}></img>
                <div className={classes.grow} />
                <div className={classes.appBarRight}>
                    
                    { 
                        profile.data?.role?.code === "admin" &&
                        
                        <Link style={{ textDecoration: 'none', marginRight:'2em' }} to={'/patient-search'}>
                            <IconButton
                                edge="end"
                                color="secondary"
                            >
                                <SearchIcon/>   
                            </IconButton>
                        </Link>
                    }

                    { 
                        profile.data?.role?.code === "admin" && 
                        !matchMD &&
                        
                        <Link style={{ textDecoration: 'none', marginRight:'2em' }} to={'/admin/users'}>
                            <IconButton
                                edge="end"
                                color="secondary"
                            >
                                <SettingsIcon/>   
                            </IconButton>
                        </Link>
                    }

                    { 
                        profile.data?.role?.code === "admin" && 
                        !matchMD &&
                        
                        <Link style={{ textDecoration: 'none', marginRight:'2em' }} to={'/board'}>
                            <IconButton
                                edge="end"
                                color="secondary"
                            >
                                <Dashboard/>   
                            </IconButton>
                        </Link>
                    }

                    { 
                        whatsappRegistration &&
                        !matchMD &&
                        profile.data?.role?.code === "patient" &&
                        profile.data?.loginSuccessful &&
                        profile.data?.whatsapp_verified_at === null && 

                        <Link style={{ textDecoration: 'none' }} to={"/whatsapp"}>
                            <Button variant="outlined" color="secondary" style={{marginRight:'2em'}}>
                            <WhatsApp style={{marginRight:'0.5em'}}/>activar notificaciones</Button>
                        </Link>
                    }

                    { 
                        whatsappRegistration &&
                        matchMD &&
                        profile.data?.role?.code === "patient" &&
                        profile.data?.loginSuccessful &&
                        profile.data?.whatsapp_verified_at === null && 

                        <Link style={{ textDecoration: 'none' }} to={"/whatsapp"}>
                            <IconButton
                                edge="end"
                                color="secondary"
                                style={{marginRight:'0.5em'}}
                                size="large"
                            >
                                <WhatsApp />
                            </IconButton>
                        </Link>
                    }

                    <Link style={{ textDecoration: 'none' }} to={profile.data?.loginSuccessful ? "profile" : ""}>
                        <IconButton edge="end" color="secondary">
                            <Avatar style={{ backgroundColor: theme.palette.status.available, color: theme.palette.arenco.lightBlue }}>{profile.data?.loginSuccessful && initials}</Avatar>
                        </IconButton>
                    </Link>

                </div>
            </Toolbar>
        </AppBar>
    );
}
