import { combineReducers } from 'redux';
import { actionTypes } from '../actions/physicians';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.searchPhyscian:
            return true
        case actionTypes.receivePhysicians:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receivePhysicians:
            return action.physicians
        case actionTypes.searchPhyscian:
            return []
        case actionTypes.clearPhysicians:
            return []
        default:
            return state
    }
}

export default combineReducers({ loading, data });