import React, { Fragment, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, MenuItem, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Add, CheckCircle, Email, Error, ThumbDown } from '@mui/icons-material';
import OriginsHandler from '../components/OriginsHandler';
import { clearUser, createSignupToken, fetchUser, resendSignupNotification, revokeUser,  updateUser } from '../actions/users';
import { selectFullUsers } from '../selectors/users';
import UserSwitch from '../components/UserSwitch';
import PhysiciansHandler from '../components/PhysiciansHandler';


const roles = [
    {
        id:1,
        code:'admin',
        name:'Administrator ROLE'
    },
    {
        id:2,
        code:'physician',
        name:'Physician ROLE'
    },
    {
        id:3,
        code:'patient',
        name:'Patient ROLE'
    }
];

const UserDialog = ({open, close,id}) => {

    const dispatch = useDispatch();
    
    const {loading,saving,saved,current,error} = useSelector(selectFullUsers);
    const [values,setValues] = useState({});

    useEffect(() => {
        dispatch(fetchUser(id));
    }, []);

    useEffect(() => {
        setValues(current);
    }, [current]);


    const handleClose = () =>{
        dispatch(clearUser());
        close();
    }

    const handleRevoke = (user) => {
        dispatch(revokeUser(user));
    }

    const handleChange = (event)=>{
        setValues({...values, [event.target.name]:event.target.value});
    }

    const handleSave = (user) => {
        dispatch(updateUser(user));
    };

    const handleResend = (token) => {
        dispatch(resendSignupNotification(token));
    };

    const handleNewToken = (user) => {
        dispatch(createSignupToken(user));
    };

    const isDirty = current?.whatsapp !== values.whatsapp || current?.email !== values.email || current?.name !== values.name || current.role_id !== values.role_id;

    return <Dialog open={open}>
            <DialogTitle>Usuario</DialogTitle>
            <DialogContent style={{padding:'2em', minWidth:'20em'}}>
                <Grid container direction='column' spacing={1} >
                    
                    {
                        current?.role?.code !== 'patient' &&
                        <Grid item container justifyContent="end" alignItems="center">
                            <Grid item xs>
                                <Typography>{values.enabled ? 'Usuario activo' :'Usuario inactivo'}</Typography>
                            </Grid>
                            <UserSwitch {...values} onChange={(enabled)=>setValues({...values,enabled:enabled})}/>
                            {
                                current?.role?.code === 'physician' &&  !current?.enabled &&
                                <IconButton onClick={()=>handleRevoke(values)}><ThumbDown></ThumbDown></IconButton>
                            }
                        </Grid>
                    }

                    <Grid item>
                        <TextField id="email" size="small" name="email" label="Email" fullWidth value={values?.email || ''} onChange={handleChange}></TextField>
                    </Grid>
                    <Grid item>
                        <TextField id="whatsapp" size="small" name="whatsapp" label="Whatsapp" fullWidth value={values?.whatsapp || ''} onChange={handleChange}></TextField>
                    </Grid>
                    <Grid item>
                        <TextField id="name" size="small" name="name" label="Nombre" fullWidth value={values?.name || ''} onChange={handleChange}></TextField>
                    </Grid>
                    <Grid item>
                        <TextField id="role_id" size="small" name="role_id" label="Rol" fullWidth select value={values?.role_id || ''} onChange={handleChange}>
                            {
                                roles.map(role => (
                                    <MenuItem key={role.id} value={role.id}>
                                        {role.name}
                                    </MenuItem>
                                ))
                            }
                        </TextField>
                    </Grid>
                    <Grid item>
                        <TextField id="created_at" size="small" name="created_at" label="Creado" fullWidth  value={values?.created_at || ''}></TextField>
                    </Grid>

                    {
                        values?.role?.code === 'patient' &&
                        <Fragment>
                            <Grid item>
                                <TextField id="email_verified_at" size="small" name="email_verified_at" label="Email desde" fullWidth  value={values.email_verified_at || 'NO REGISTRADO'}></TextField>
                            </Grid>
                            <Grid item>
                                <TextField id="whastapp_verified_at" size="small" name="whastapp_verified_at" label="Whastapp desde" fullWidth  value={values.whatsapp_verified_at || 'NO REGISTRADO'}></TextField>
                            </Grid>
                        </Fragment>
                    }
                    {
                        values?.role?.code === 'patient' &&
                        <Grid item container direction="column" style={{marginTop:'1em'}}>
                            {
                                values?.signup_tokens?.length &&
                                values?.signup_tokens.map(token => 
                                    <Grid item container alignItems="center">
                                        <Grid item xs>
                                            <Typography>{`Token de registro ${token.id} válido hasta ${token.expires_at}`}</Typography>
                                        </Grid>
                                        <IconButton onClick={()=>handleResend(token)}><Email></Email></IconButton>
                                </Grid>)
                            }
                           <Grid item container style={{marginTop:'1em'}}>
                                <Button fullWidth variant="outlined" startIcon={<Add/>} onClick={()=>handleNewToken(values)}>Token</Button>
                            </Grid>
                        </Grid>
                    }

                    {   
                        values?.role?.code === 'physician' && 
                        <Grid item>
                            <OriginsHandler user={values} textFieldProps={{variant:'outlined', label:'Origenes', placeHolder:'Origenes'}}/>
                        </Grid>
                    }

                    {   
                        values?.role?.code === 'physician' && 
                        <Grid item>
                            <PhysiciansHandler user={values} textFieldProps={{variant:'outlined', label:'Medico', placeHolder:'Medico'}}/>
                        </Grid>
                    }

                    {              
                        error !== "" && 
                        <Grid item container >
                            <Error color="primary" style={{marginBottom:'1em'}}></Error>
                            <Typography textAlign="center" variant="body1" color="primary" style={{marginLeft:'0.5em'}}>{error}</Typography>
                        </Grid>
                    }

                    {              
                        saved && 
                        <Grid item container >
                            <CheckCircle color="primary" style={{marginBottom:'1em'}}></CheckCircle>
                            <Typography textAlign="center" variant="body1" color="primary" style={{marginLeft:'0.5em'}}>Cambios guardados</Typography>
                        </Grid>
                    }

                    {              
                        (saving || loading) && 
                        <Grid item>
                            <LinearProgress/>
                        </Grid>
                    }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>handleClose()}>Cerrar</Button>
                <Button 
                    variant="contained"
                    color="secondary"
                    disabled={!isDirty}
                    sx={{height:'2.5em', textTransform:'none', paddingLeft:'2em', paddingRight:'2em'}}
                    onClick={()=>handleSave(values)}>
                        <Typography>
                            Guardar
                        </Typography>
                </Button>
            </DialogActions>
        </Dialog>}

export default UserDialog;
