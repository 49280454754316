import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchDocumentToken: 'FETCH_DOCUMENT_TOKEN',
    receiveDocumentToken : 'RECEIVE_DOCUMENT_TOKEN',
    clearDocumentToken:'CLEAR_DOCUMENT_TOKEN',
    rejectDocumentToken:'REJECT_DOCUMENT_TOKEN'
};


export const rejectDocumentToken = (error) => ({ type: actionTypes.rejectDocumentToken, error });
export const receiveDocumentToken = (documentToken) => ({ type: actionTypes.receiveDocumentToken, documentToken });
export const clearDocumentToken = () => ({ type: actionTypes.clearDocumentToken });

export const fetchDocumentToken = (token) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchDocumentToken });

        const http = new HttpService();

        return http.getData(`custom/document-management/document/token/${token}`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(documentToken => {

                if (documentToken?.error){
                    dispatch(rejectDocumentToken(documentToken?.error));
                }
                else{
                    dispatch(receiveDocumentToken(documentToken));
                }
            })
    }
}


