import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import History from './History';
import Result from './Result';
import ReferenceValue from './ReferenceValue';
import ReferenceText from './ReferenceText';


const useStyles = makeStyles((theme) => ({
    testContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    nameContainer: {

        backgroundColor: '#edf1f4',
        minHeight: '3em',
        paddingLeft: '1.5em',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
        padding: '1.5em'


    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 3
    },
    resultContainer: {
        marginBottom: '1em'

    },
    referenceContainer: {

    },
    historyContainer: {
        flexGrow: 1,
        minWidth: '25em',
        maxWidth: '25em',

        [theme.breakpoints.down('lg')]: {
            marginLeft: '0em'
        },
        marginLeft: '1.5em'
    }
}));

const Test = ({ test }) => {

    const classes = useStyles();

    return (
        <div id={`test_${test.id}`} className={classes.testContainer} >
            <div className={classes.nameContainer} >
                <Typography style={{ textTransform: 'uppercase' }}><Box fontWeight={600}>{test?.test_type_name}</Box></Typography>
                <Typography variant="body1">{test?.method}</Typography>
            </div>
            <div className={classes.contentContainer} >
                <div className={classes.leftContainer} >
                    <div className={classes.resultContainer} >
                        <Result result={test?.result}></Result>
                    </div>
                    <div className={classes.referenceContainer} >

                        {
                            test?.result.reference_text?.length > 0 &&
                            <ReferenceText referenceText={test.result.reference_text}/>
                        }
                        
                        {
                            test?.result.reference_text?.length == 0 && test?.result?.reference_value && test?.result?.reference_value?.max > 0 &&
                            <ReferenceValue result={test?.result}></ReferenceValue>
                        }

                    </div>
                </div>

                <div className={classes.historyContainer} >
                    {
                        !test.result.large && 
                        <History test={test} />
                    }
                </div>
            </div>
        </div>
    );
};

export default Test;