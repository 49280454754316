import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchUsers: "FETCH_USERS",
    fetchUser: "FETCH_USER",
    receiveUsers: "RECEIVE_USERS",
    clearUsers:"CLEAR_USERS",
    clearUser:"CLEAR_USER",
    updateUser:"UPDATE_USER",
    receiveUser:"RECEIVE_USER",
    setUser:"SET_USER",
    setError:"UPDATE_USER_ERROR",
    setSaved:"SAVED_USER",
    setDeleted:"DELETED_USER",
    revokeUser:"REVOKE_USER"
};

export const clearUsers = () => ({ type: actionTypes.clearUsers});
export const clearUser = (user) => ({ type: actionTypes.clearUser,user});

const receiveUsers = (users) => ({ type: actionTypes.receiveUsers, users });

const receiveUser = (user) => ({ type: actionTypes.receiveUser, user });

export const setUser = (user) => ({ type: actionTypes.setUser, user });

export const resendSignupNotification = (token) => {
    return (dispatch) => {
        
        const http = new HttpService();
         return http.postData({},`custom/signup-management/resend-registry/${token.id}`, '')
             .then(response => {
                 if (response?.ok) {
                     return response.json();
                 }
                 else return Promise.reject();
             })
    }
}

export const createSignupToken = (user) => {
    return (dispatch) => {

       dispatch({ type: actionTypes.updateUser });
       const http = new HttpService();
        
        return http.postData(user,`custom/signup-management/create-registry-token/${user.id}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(user => {
                dispatch(setUser(user));
                dispatch(receiveUser(user));
            })
    }
}

export const updateUser = (user) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateUser });
       const http = new HttpService();
        
        return http.putData(user,`resource/users/${user.id}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                } else if (response.status===400){
                    return response.json() // return was missing here
                    .then(({error})=>{
                      throw new Error(error);
                    })
                } else return Promise.reject(response);
            })

            .then( user => {
                if (user){
                    dispatch({ type: actionTypes.setSaved })
                    dispatch(setUser(user));
                    dispatch(receiveUser(user));
                }
            })

            .catch(error => {
                dispatch({ type: actionTypes.setError, error:error.message });
            })

    }
}

export const revokeUser = (user) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.revokeUser });
        const http = new HttpService();
        const body = {user_id : user.id};

        return http.postData(body,`custom/signup-management/revoke-user`, '')

            .then(response => {
                if (response?.ok) {
                    return response.json();
                } else if (response.status===400){
                    return response.json() // return was missing here
                    .then(({error})=>{
                      throw new Error(error);
                    })
                } else return Promise.reject(response);
            })

            .then( user => {
                if (user){
                    dispatch({ type: actionTypes.setDeleted })
                    dispatch(clearUser(user));
                }
            })

            .catch(error => {
                dispatch({ type: actionTypes.setError, error:error.message });
            })

    }
}
export const changeUserStatus = (id, enabled) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateUser });

        const http = new HttpService();
        const body = {user_id : id, enabled:enabled};

        return http.postData(body,`custom/signup-management/change-user-status`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(user => {
                dispatch(setUser(user));
                dispatch(receiveUser(user));
            })
    }
}

export const changeUserOrigins = (id, origins) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateUser });

        const http = new HttpService();

        const body = {user_id : id, origins:origins};
        
        return http.postData(body,`custom/signup-management/change-user-origins`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(user => {
                dispatch(setUser(user));
                dispatch(receiveUser(user));
            })
    }
}

export const assignUserPhysician = (id, physician_id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.updateUser });

        const http = new HttpService();

        const body = {user_id : id, physician_id:physician_id};
        
        return http.postData(body,`custom/signup-management/assign-user-physician`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(user => {
                dispatch(setUser(user));
                dispatch(receiveUser(user));
            })
    }
}

export const fetchUser = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchUser });

        const http = new HttpService();

        return http.getData(`resource/users/${id}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(user => {
                dispatch(receiveUser(user));
            })
    }
}
export const fetchUsers = (params) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchUsers });

        const http = new HttpService();
        const queryParams = params?.join('&');

        return http.getData(`resource/users`, null,queryParams)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(users => {
                dispatch(receiveUsers(users));
            })
    }
}

export const fetchUsersPage = (page) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchUsers });

        const http = new HttpService();

        return http.getData(`resource/users?page=${page}`, null,null)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(users => {
                dispatch(receiveUsers(users));
            })
    }    
}