import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchPatient: "FETCH_PATIENT",
    receivePatient: "RECEIVE_PATIENT",
    clearPatient: "CLEAR_PATIENT",
    clearPatients: "CLEAR_PATIENTS",
    searchPatient: "SEARCH_PATIENT",
    receivePatients: "RECEIVE_PATIENTS"
};

const receivePatients = (patients) => ({ type: actionTypes.receivePatients, patients });
const receivePatient = (patient) => ({ type: actionTypes.receivePatient, patient });

export const clearPatient = { type: actionTypes.clearPatient };

export const fetchPatient = (id, number) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchPatient });

        const http = new HttpService();

        return http.getData(id ? `patients` : `patients/${number}`, 'user-token', id && { id: id })
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(patient => {
                dispatch(receivePatient(patient));
            })
    }
}

export const searchPatient = (searchParams) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearPatients });
        dispatch({ type: actionTypes.searchPatient });

        const http = new HttpService();

        return http.getData(`custom/patient-management/search-patient`, 'user-token', searchParams)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();

            })
            .then(patients => {
                dispatch(receivePatients(patients));
            })
    }
}