import React from 'react';
import { Box, Button, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchPatient } from '../actions/patient';
import { selectPatients } from '../selectors/patient';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

const PatientSearch = () => {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useState({ number: '', last_name: '', first_name: '' });
    const patients = useSelector(selectPatients);

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 150,
            sortable: false,
            hide: true
        },
        {
            field: 'number',
            headerName: 'Historia clinica',
            width: 200,
            sortable: false
        },
        {
            field: 'last_name',
            headerName: 'Apellido',
            width: 200,
            sortable: false
        },
        {
            field: 'first_name',
            headerName: 'Nombre',
            width: 200,
            sortable: false
        },
        {
            field: 'view_orders_list',
            headerName: '',
            width: 30,
            renderCell: (params) => <Link to={`/orders/${params.row.id}`}>
                <Tooltip title="Lista de ordenes">
                    <IconButton><FormatListBulletedIcon color="primary"></FormatListBulletedIcon></IconButton>
                </Tooltip>
            </Link>,
            type: 'actions',
            resizable: false,

        },
        {
            field: 'view_clinical_view',
            headerName: '',
            width: 30,
            renderCell: (params) => <Link to={`/clinical-view/${params.row.id}`}>
                <Tooltip title="Vista de historia clinica">
                    <IconButton ><LocalHospitalIcon color="primary"></LocalHospitalIcon></IconButton>
                </Tooltip>
            </Link>,
            type: 'actions',
            resizable: false,
        },
    ];
    const data = { rows: patients.data, columns: columns };

    const handleSearch = () => {

        const cleanSearchParams = searchParams;

        cleanSearchParams.number === '' && delete cleanSearchParams.number;
        cleanSearchParams.last_name === '' && delete cleanSearchParams.last_name;
        cleanSearchParams.first_name === '' && delete cleanSearchParams.first_name;

        dispatch(searchPatient(searchParams));
    }


    const handleKeyPress = (event) => {
        if (event.keyCode === 13 && canSearch) {
            handleSearch();
        }

    }

    const canSearch = !!searchParams.number || !!searchParams.last_name || !!searchParams.first_name;

    return (

        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Busqueda de paciente</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '1em', marginBottom: '1em', paddingLeft: '2em', paddingRight: '2em' }}>
                    <div style={{ paddingRight: '1em', flexGrow: 1 }}>
                        <TextField size="small" variant="outlined" placeholder="Historia clinica" fullWidth value={searchParams.number} onKeyDown={handleKeyPress} onChange={(event) => setSearchParams({ ...searchParams, number: event.target.value })} />
                    </div>
                    <div style={{ paddingRight: '1em', flexGrow: 1 }}>
                        <TextField size="small" variant="outlined" placeholder="Apellido" fullWidth value={searchParams.last_name} onKeyDown={handleKeyPress} onChange={(event) => setSearchParams({ ...searchParams, last_name: event.target.value })} />
                    </div>
                    <div style={{ paddingRight: '1em', flexGrow: 1 }}>
                        <TextField size="small" variant="outlined" placeholder="Nombre" fullWidth value={searchParams.first_name} onKeyDown={handleKeyPress} onChange={(event) => setSearchParams({ ...searchParams, first_name: event.target.value })} />
                    </div>

                    <Button variant="contained" disabled={!canSearch} onClick={() => handleSearch()}>
                        <SearchIcon />
                    </Button>
                </div>
            </div>
            <div style={{ flexGrow: '1', overflowY: 'auto', paddingLeft: '2em', paddingRight: '2em' }}>
                <DataGridPro
                    {...data}
                    headerHeight={60}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                />
            </div>
        </div >
    );
};

export default PatientSearch;
