import React, { Fragment } from 'react';import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import clock from './../assets/clock.png';
import { validateToken } from '../actions/signup';
import { selectSignupData } from '../selectors/signup';

const PhysicianVerification = () => {

    const { key } = useParams();
    const dispatch = useDispatch();
    const signupData = useSelector(selectSignupData);

    useEffect(() => {
        dispatch(validateToken(key));
    }, []);

    
    return(
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh',alignItems: 'center' }}>
                <div style={{ height: '5em', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom:'2em' }}>
                    {
                        (signupData.tokenStatus.valid || signupData.tokenStatus.userVerified)  &&  
                        <Typography textAlign="center" variant="h5" color="text.black"  ><Box fontWeight={750}>Email confirmado</Box></Typography>
                    }
                    
                    {   
                        signupData.tokenStatus.validating && 
                        <Typography textAlign="center" variant="h6" color="text.black"  ><Box fontWeight={750}>Estamos verificando el Link</Box></Typography>
                    }
                    
                    {
                        !signupData.tokenStatus.valid  && !signupData.tokenStatus.validating && !signupData.tokenStatus.userVerified &&
                        <Typography textAlign="center" variant="h5" color="text.black"  >
                            <Box fontWeight={750}>El link ya no esta disponible.</Box>
                        </Typography>
                    }

                    {    
                        !signupData.tokenStatus.valid  && !signupData.tokenStatus.validating && !signupData.tokenStatus.userVerified &&
                        <Typography textAlign="center" variant="body1" color="text.black"  >
                            <Box fontWeight="light">El link es invalido o esta vencido.</Box>
                        </Typography>
                    }

                </div>
                <div style={{minWidth:'8em'}}>
                    {signupData.tokenStatus.validating && <LinearProgress color="primary" />}
                </div>
                <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center', marginBottom:'2em' }}>
                    {
                        (signupData.tokenStatus.valid || signupData.tokenStatus.userVerified)  && 
                        <img src={clock}></img>
                    }
                </div>                
                <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        (signupData.tokenStatus.valid || signupData.tokenStatus.userVerified)  && 
                        <Typography textAlign="center" variant="body1" color="text.black"  >
                            <Box fontWeight="light">Hemos confirmado tu email con exito</Box>
                            <Box fontWeight="light">Tu solicitud sera confirmada a la brevedad</Box>
                            <Box fontWeight="light">Te avisaremos por email</Box>
                        </Typography>
                    }
                </div>

        </div>
    )
}

export default PhysicianVerification