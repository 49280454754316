export const isAvailable = (order, role) => {
    return  (
        (role === 'patient' && order.admin_status === 'success' &&  order.released) || 
                ((role === 'physician' || role === 'admin') && (order.status === 'posted' || order.status === 'partial')));
};

export const isReportAvailable = (order, role) => {
    return  (
        (role === 'patient' && order.admin_status === 'success' &&  order.released) || 
                ((role === 'physician' || role === 'admin') && (order.status === 'posted' || order.status === 'partial')));
};
