import { combineReducers } from 'redux';
import { actionTypes } from '../actions/patient';
import { actionTypes as profileActions } from '../actions/profile';



const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchPatient:
            return true
        case actionTypes.receivePatient:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receivePatient:
            return action.patient
        case actionTypes.clearPatient:
            return {}
        case profileActions.clearProfile:
            return {}
        default:
            return state

    }
}

export default combineReducers({ loading, data });