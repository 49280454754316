import { Email, Error, SettingsSystemDaydreamOutlined } from "@mui/icons-material";
import { Box, Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearRecoverToken, recoverToken } from "../actions/signup";
import { selectSignupData } from "../selectors/signup";
import { Link } from "react-router-dom";

const PatientSignupEmail = ({}) => {

    const dispatch = useDispatch();
    const [email,setEmail] = useState();
    const {emailStatus} = useSelector(selectSignupData);

    useEffect(() => {

        return () => {
            dispatch(clearRecoverToken());
            setEmail('');
        }
    }, []);


    const handleClick = (event) =>{
        dispatch(recoverToken(email));
    }


 return(
    <Grid container direction="column" >
        <Grid item style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Registro</Box></Typography>
        </Grid>
        <Grid item xs container direction="column" spacing={3} alignItems="center">
            
            <Grid item style={{marginTop:'3em'}}>
                <Typography textAlign="center">Por favor ingresa tu email <br/></Typography>
                <Typography textAlign="center">y sigue las instrucciones que te enviaremos.</Typography>
            </Grid>
            
            <Grid item style={{minWidth:'30em'}}>
                <TextField id="email" name="email" size="small" fullWidth value={email} onChange={(event)=>setEmail(event.target.value)}></TextField>
            </Grid>
            
            <Grid item>
                <Button variant="contained" onClick={handleClick} disabled={emailStatus?.validating || emailStatus?.valid}>Verificar</Button>
            </Grid>
            
            {
                emailStatus?.validating &&

                <Grid item>
                    <LinearProgress color="primary" style={{ height: '0.5em', minWidth:'10em' }}/>
                </Grid>
            }

            {
                emailStatus?.error && emailStatus.code === '01' &&
                <Grid item xs={3} container direction="column" alignItems="center">
                    <Error color="primary" style={{marginBottom:'1em'}}></Error>
                    <Typography textAlign="center" variant="body1" color="primary" >El email ingresado no existe en nuestros registros.</Typography>
                </Grid>
            }

            {
                emailStatus?.error && emailStatus.code === '02' &&
                <Grid item xs={3} container direction="column" alignItems="center">
                    <Error color="primary" style={{marginBottom:'1em'}}></Error>
                    <Typography textAlign="center" variant="body1" color="primary" >El email ya esta registrado.</Typography>
                    <Typography textAlign="center" variant="body1" color="primary" style={{marginBottom:'1em'}} >Si olvidaste tu contraseña podes recuperarlo aquí.</Typography>
                     <Link to={`/forgot?email=${email}`} style={{textDecoration:'none'}}>
                        <Button size="small" variant="outlined" fullWidth>Olvide la contraseña</ Button>
                    </Link>
                </Grid>
            }

            {
                emailStatus?.error && emailStatus.code === '03' &&
                <Grid item xs={3} container direction="column" alignItems="center">
                    <Error color="primary" style={{marginBottom:'1em'}}></Error>
                    <Typography textAlign="center" variant="body1" color="primary" >El email ingresado no puede ser registrado.</Typography>
                    <Typography textAlign="center" variant="body1" color="primary" >Comuniquese con el Laboratorio.</Typography>
                </Grid>
            }

            {
                emailStatus?.valid &&
                <Grid item xs={3} container direction="column" alignItems="center">
                    <Email color="primary" style={{marginBottom:'1em'}}></Email>
                    <Typography textAlign="center" variant="body1" color="primary" >Listo!</Typography>
                    <Typography textAlign="center" variant="body1" color="primary" >Revisa tu email y continua con el proceso de registro.</Typography>
                </Grid>
            }

        </Grid>
    </Grid>
)
};

export default PatientSignupEmail;