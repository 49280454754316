import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchOrigins: "FETCH_ORIGINS",
    receiveOrigins: "RECEIVE_ORIGINS"
};

const receiveOrigins = (origins) => ({ type: actionTypes.receiveOrigins, origins });

export const fetchOrigins = () => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrigins });

        const http = new HttpService();

        return http.getData(`origins`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(origins => {
                dispatch(receiveOrigins(origins));
            })
    }
}