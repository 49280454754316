import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchResults: "FETCH_RESULTS",
    fetchPatientResults: "FETCH_PATIENT_RESULTS",
    receiveResults: "RECEIVE_RESULTS",
    receivePatientResults: "RECEIVE_PATIENT_RESULTS",
    clearResults: "CLEAR_RESULTS",
    clearPatientResults: "CLEAR_PATIENT_RESULTS"
};

const receiveResults = (results) => ({ type: actionTypes.receiveResults, results });
const receivePatientResults = (results) => ({ type: actionTypes.receivePatientResults, results });
export const clearResults = { type: actionTypes.clearResults };
export const clearPatientResults = { type: actionTypes.clearPatientResults };

export const fetchResults = (order_number) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchResults });

        const http = new HttpService();

        return http.getData(`orders/${order_number}/results`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(orders => {
                dispatch(receiveResults(orders));
            })
    }
}

export const fetchPatientResults = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchPatientResults });

        const http = new HttpService();

        return http.getData(`patients/${id}/results`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(orders => {
                dispatch(receivePatientResults(orders));
            })
    }
}
