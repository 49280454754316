
import HttpService from "../services/HttpService";

export const actionTypes = {
    setVerificationToken :"SET_VERIFICATION_TOKEN",
    validatingVerificationToken:"VALIDATING_VERIFICATION_TOKEN",
    acceptVerificationToken:"ACCEPT_VERIFICATION_TOKEN",
    refuseVerificationToken:"REFUSE_VERIFICATION_TOKEN",
    
    setChangeToken :"SET_CHANGE_TOKEN",
    validatingChangeToken:"VALIDATE_CHANGE_TOKEN",
    acceptChangeToken:"ACCEPT_CHANGE_TOKEN",
    refuseChangeToken:"REFUSE_CHANGE_TOKEN",
    
    validatingCode:"VALIDATING_CODE",
    acceptCode:"ACCEPT_CODE",
    rejectCode:"REJECT_CODE",

    changingPassword:"CHANGING_PASSWORD",
    acceptPassword:"ACCEPT_PASSWORD",
    refusePassword:"REFUSE_PASSWORD",
    
    validateEmail:"VALIDATE_EMAIL",
    validatingEmail:"VALIDATING_EMAIL_FORGOT",
    acceptEmail:"ACCEPT_EMAIL_FORGOT",
    refuseEmail:"REFUSE_EMAIL",

};

export const validateEmail = (email) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.setVerificationToken, token:'' });
        dispatch({ type: actionTypes.validatingEmail});

        const http = new HttpService();
        return http.getData(`custom/forgot/email/${email}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {
                if (response.valid_user)
                {
                    dispatch({type:actionTypes.setVerificationToken, token:response.verification_token});
                    dispatch({type:actionTypes.acceptEmail});
                }
                else
                {
                    dispatch({type:actionTypes.refuseEmail});
                }
            })
    }
}

export const validateVerificationToken = (token) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.setVerificationToken, token:token });
        dispatch({ type: actionTypes.validatingVerificationToken});

        const http = new HttpService();
        return http.getData(`custom/forgot/validate-verification-token/${token}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(tokenStatus => {
                tokenStatus.valid_token ?
                    dispatch({type:actionTypes.acceptVerificationToken}):
                    dispatch({type:actionTypes.refuseVerificationToken});
            })
    }
}

export const validateCode = (code,token) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.validatingVerificationToken});

        const http = new HttpService();
        return http.getData(`custom/forgot/validate/${token}/code/${code}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {
                if (response.valid) {
                    dispatch({type:actionTypes.setChangeToken, token:response.change_token});
                    dispatch({type:actionTypes.acceptVerificationToken});
                }
                else
                {
                    dispatch({type:actionTypes.refuseVerificationToken, error:response.error});
                }
            })
    }
}


export const validateChangeToken = (token) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.setChangeToken, token:token });
        dispatch({ type: actionTypes.validateChangeToken});

        const http = new HttpService();
        return http.getData(`custom/forgot/validate-change-token/${token}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(tokenStatus => {
                tokenStatus.valid_token ?
                    dispatch({type:actionTypes.acceptChangeToken}):
                    dispatch({type:actionTypes.refuseChangeToken});
            })
    }
}

export const changePassword = (password, token) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.changingPassword});

        const http = new HttpService();
        return http.postData({ password: password}, `custom/forgot/change/${token}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {
                response.valid ? 
                    dispatch({type:actionTypes.acceptPassword}):
                    dispatch({type:actionTypes.refusePassword, error:response.error});
            })
    }
}
