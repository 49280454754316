import { combineReducers } from 'redux';
import { actionTypes } from '../actions/orders';
import { actionTypes as profileActions } from '../actions/profile';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchOrders:
            return true
        case actionTypes.receiveOrders:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.receiveOrders:
            return action.orders
        case actionTypes.clearOrders:
            return []
        case profileActions.clearProfile:
            return []
        default:
            return state

    }
}

export default combineReducers({ loading, data });