import HttpService from "../services/HttpService";

export const actionTypes = {
    setProfile: "SET_PROFILE",
    fetchProfile: "FETCH_PROFILE",
    tryLogin: 'TRY_LOGIN',
    clearProfile: "CLEAR_PROFILE",
    loginFailed: "LOGIN_FAILED",
    setTokens: 'SET_TOKENS',
};

export const clearProfile = (profile) => ({ type: actionTypes.clearProfile })
export const setProfile = (profile) => ({ type: actionTypes.setProfile, profile })
export const setTokens = (tokens) => ({ type: actionTypes.setTokens, tokens })
export const loginFailed = (error) => ({ type: actionTypes.loginFailed, error })



export const user = (tokens = null) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchProfile });

        const http = new HttpService();
        return http.getData('user', 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })

            .then(user => {
                dispatch(setProfile(user));
            })
    }
}

export const login = (credentials) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.tryLogin });

        const http = new HttpService();

        return http.doLogin(credentials)
            .then(response => {
                if (response?.ok)
                {
                    return response.json().then(
                        login => {
        
                            if (credentials.remember) {
                                http.setAuthCookie(login.access_token, login.refresh_token)
                            } else {
                                http.removeAuthCookie()
                            }
            
                            dispatch(setTokens(login));
                            dispatch(user());
                        }
                    );
                } else
                {
                    if (response.status === 401)
                    {
                        return response.json().then(
                            login => {
                                dispatch(loginFailed("Usuario o contraseña incorrecta"));
                            }
                        );
    
                    }

                    return response.json().then(
                        login => {
                            dispatch(loginFailed(login.message));
                        }
                    );
                }
            })
    }
}

export const logout = (credentials) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.clearProfile });
        const http = new HttpService();
        http.removeAuthCookie();
    }
}

export const refreshToken = () => {


}

