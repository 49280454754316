import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';


const OrderAdminStatus = ({ status }) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const orderAdminStatus = {
        pending: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.pending, borderRadius: 7 }, description: 'PENDIENTE', mobileDescription: 'P' },
        rejected: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.available, borderRadius: 7 }, description: 'RECHAZADO', mobileDescription: 'R' },
        success: { style: { width: '10em', padding: '0.3em', color: 'white', backgroundColor: theme.palette.status.available, borderRadius: 7 }, description: 'APROBADO', mobileDescription: 'A' },
    }

    return (

        <Typography
            style={orderAdminStatus[status].style}
            align="center"
            variant="body2">
            <Box fontWeight={'600'}>{ matchMD ? orderAdminStatus[status].mobileDescription : orderAdminStatus[status].description}</Box>
        </Typography >

    );
};

export default OrderAdminStatus;