import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import PhysicianSearch from './PhysicianSearch';


const PhysiciansDialog = ({ open, value, close }) => {

    const [selected, setSelected] = useState(value);

    const handleSelect = (data) => {
        setSelected(data);
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='md'>
            <DialogTitle id="station-dialog-title">Buscar Medicos</DialogTitle>
            <DialogContent>
                <PhysicianSearch onSelect={handleSelect}/>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" variant="contained" onClick={() => close(selected)} >ACEPTAR</Button>
                <Button color="secondary" variant="contained" onClick={() => close(value)}>CANCELAR</Button>
            </DialogActions>
        </Dialog>);
};

export default PhysiciansDialog;


