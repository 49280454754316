import { Archive, CleanHands, Clear, ClearAll, ClearOutlined, Delete, Unarchive, Visibility } from "@mui/icons-material";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { assignUserPhysician, changeUserOrigins } from "../actions/users";
import { selectOrigins } from "../selectors/catalogs";
import PhysiciansDialog from "../views/PhysiciansDialog";
import UserStatus from "./UserStatus";



const PhysiciansHandler = ({user, textFieldProps}) => {

    const dispatch = useDispatch();
    const [openDialog, toggleDialog] = useState(false);
    const origins = useSelector(selectOrigins);

    const handleClick = () => {
        toggleDialog(true);
    }

    const handleClear = () => {
        dispatch(assignUserPhysician(user.id, ''));
    }

    const handleCloseDialog = (data) => {
        if (user.physicians[0]?.id !== data.id){
            dispatch(assignUserPhysician(user.id, data.id));
        }

        toggleDialog(false);
    };

    return <Fragment>
            <TextField
                id="origins"
                size='small'
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true,endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => handleClick()}><Visibility /></IconButton>
                    <IconButton onClick={() => handleClear()}><Clear /></IconButton>
                    </InputAdornment> }}
                value={user.physicians?.length ? `${user.physicians[0]?.id} - ${user.physicians[0]?.first_name} ${user.physicians[0]?.last_name}` : ''}
                {...textFieldProps}
            />
                {
                    openDialog &&
                    <PhysiciansDialog open={openDialog} value={user.physicians[0]} close={handleCloseDialog} />
                }
        </Fragment>
}

export default PhysiciansHandler;