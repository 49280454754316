import React from 'react';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';

import { gender } from '../translators/gender';
import { useTheme } from '@mui/system';
import { format, parseISO } from 'date-fns';

const PatientHeader = ({ patient, age, onClean, order }) => {

    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f4f4f4', paddingLeft: matchMD ? '1em' : '3em', paddingTop: matchMD ?'1em' : '2em', paddingBottom: matchMD ? "0.5em" : "2em" }}>

            <div style={{ display: 'flex', flexDirection: matchMD ? 'column' : 'row', flexGrow: 1 }}>

                <div style={{ display: 'flex', flexDirection: matchMD ? 'column' : 'row', flexGrow: 1 }}>
                    <Typography variant={matchMD ? "h5" : "h4"} color="text.black"><Box fontWeight={750}>{`${patient?.first_name || ''} ${patient?.last_name || ''}`}</Box></Typography>
                    {
                        !matchMD && <Typography variant="h4" color="text.black" style={{ marginLeft: '0.5em', marginRight: '0.5em' }}><Box fontWeight={750}>{`${patient?.number ? '/' : ''}`}</Box></Typography>
                    }
                    <Typography variant={matchMD ? "h5" : "h4"} color="text.black"><Box fontWeight={750}>{`${patient?.number || ''}`}</Box></Typography>
                </div>
                {
                    !matchMD && <Divider orientation="vertical" flexItem style={{ marginLeft: '2em', marginRight: '2em' }} />
                }
                <div style={{ dispaly: 'flex', flexDirection: 'column', marginTop: matchMD && '1em', flexGrow: 1 }}>
                    <Typography variant="body1" color="text.black">{age && `Edad ${age}`}</Typography>
                    <Typography variant="body1" color="text.black"> {gender(patient?.gender)}</Typography>
                    <Typography variant="body1" color="text.black">{patient?.email}</Typography>
                </div>

            </div>
            {
                order && !matchMD && <Divider orientation="vertical" flexItem style={{ marginLeft: '2em', marginRight: '2em' }} />
            }

            {
                order &&
                <div style={{ dispaly: 'flex', flexDirection: 'column', marginTop: matchMD && '1em', flexGrow: 1 }}>
                    <Typography variant="h6" color="text.black">{order?.number}</Typography>
                    <Typography variant="body1" color="text.black">{order?.date && format(parseISO(order?.date), 'dd/MM/yyyy')}</Typography>
                    <Typography variant="body1" color="text.black"> {order?.origin}</Typography>
                    <Typography variant="body1" color="text.black">{order?.servicel}</Typography>
                    <Typography variant="body1" color="text.black">{order?.diagnostic}</Typography>
                    <Typography variant="body1" color="text.black">{order?.physician}</Typography>
                </div>

            }

        </div>
    );
};

export default PatientHeader;