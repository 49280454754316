import HttpService from "../services/HttpService";

export const fetchDocument = (document_id, order_number) => {
    return (dispatch) => {

        const http = new HttpService();

        return http.getPDF(`documents/${document_id}`, 'user-token')
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Informe de resultados - Orden: ${order_number}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }
}
