import React, { useState } from 'react';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Paper, Typography, useMediaQuery } from '@mui/material';
import { Close, Email, Timer } from '@mui/icons-material';


const PendingStatusDialog = ({ open, close }) => {

    return (
        <Dialog
            open={open}
            fullWidth
            PaperProps={{ style: { padding: '1em' } }}>
            <DialogTitle>
                <div style={{display:'flex'}}>
                    <div style={{flexGrow:1}}></div>
                    <div>
                        <IconButton onClick={() => close()}>
                            <Close/>
                        </IconButton> </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                    <div style={{marginBottom:'1em'}}>
                        <Timer color="primary"></Timer>
                    </div>
                    <Typography variant="body1">La orden esta en proceso.</Typography>
                    
                    <div style={{marginTop:'1em', marginBottom:'1em'}}>
                        <Email color="secondary"></Email>
                    </div>

                    <Typography variant="body1">Te notificaremos </Typography>
                    <Typography variant="body1">cuando este disponible.</Typography>
                </div>                    
            </DialogContent>
        </Dialog>);
};

export default PendingStatusDialog;                    <div style={{marginBottom:'1em'}}>
<Timer color="primary"></Timer>
</div>


