import { combineReducers } from 'redux';
import { actionTypes } from '../actions/whatsapp';


const statusTemplate = {
    sended:false,
    valid:false,
    sending:false,
    validating:false,
    error:'',
    verificationToken:''
};

const status = (state = statusTemplate, action) => {
    switch (action.type) {
        case actionTypes.sendCode:
            return {...state, sending:true,error:''}
        case actionTypes.deliverCode:
            return {...state, sending:false, sended:true, verificationToken:action.verificationToken}
        case actionTypes.validateCode:
            return {...state, validating:true, error:''}
        case actionTypes.rejectCode:
            return {...state, valid:false, validating:false}
        case actionTypes.acceptCode:
            return {...state, valid:true, validating:false}
        case actionTypes.error:
            return {...state, error:action.error, sending:false, validating:false}
        case actionTypes.reset:
            return {...statusTemplate}
        default:
            return state
    }
}

export default combineReducers({ status });