import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchNotificationLogs: "FETCH_NOTIFICATION_LOG",
    receiveNotificationLogs: "RECEIVE_NOTIFICATION_LOG",
    clearNotificationLogs : "CLEAR_NOTIFICATION_LOGS"
};

const receiveNotificationLogs = (notificationLogs) => ({ type: actionTypes.receiveNotificationLogs, notificationLogs });

export const clearNotificationLogs = { type: actionTypes.clearNotificationLogs };

export const fetchNotificationLogs = (from,to) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchNotificationLogs });

        const http = new HttpService();

        return http.getData(`notificationlogs?from=${from}&to=${to}`)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(notificationLogs => {
                dispatch(receiveNotificationLogs(notificationLogs));
            })
    }
}
