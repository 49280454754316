import { combineReducers } from 'redux';
import { actionTypes } from '../actions/useLogs';


const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchUseLogs:
            return true
        case actionTypes.receiveUseLogs:
            return false
        default:
            return state
    }
}

const data = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchUseLogs:
            return []
        case actionTypes.clearUseLogs:
            return []
        case actionTypes.receiveUseLogs:
            return action.useLogs
        default:
            return state
    }
}

export default combineReducers({ loading, data });