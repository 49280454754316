import { Warning } from "@mui/icons-material";
import { Box, Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reset, sendCode, validateCode } from "../actions/whatsapp";
import { selectStatus } from "../selectors/whatsapp";

const PatientLoadWhatsapp = ({}) => {

    const dispatch = useDispatch();

    const [whatsapp,setWhatsapp] = useState('');
    const [code,setCode] = useState('');

    const status = useSelector(selectStatus);

    useEffect(() => {
        return () => {
            setWhatsapp('');
            setCode('');
            dispatch(reset());
        }
    }, []);

    const handleSendCode = () =>{
        dispatch(sendCode(whatsapp));
    }

    const handleVerifyCode = () => {
        dispatch(validateCode(status.verificationToken,code, whatsapp))
    };

    const handleReset = () => {
        setWhatsapp('');
        setCode('');
        dispatch(reset());
    };

 return(
    <Grid container direction="column" >
        <Grid item style={{ backgroundColor: '#f4f4f4', height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Registro de whatsapp</Box></Typography>
        </Grid>

        <Grid item xs container direction="column" spacing={3} alignItems="center">
            
            <Grid item style={{marginTop:'3em'}}>
                <Typography textAlign="center">Por favor ingresa tu numero de Whatsapp <br/></Typography>
                <Typography textAlign="center">Tal como figura en tu perfil. Sin espacios ni guiones.</Typography>
                <Typography textAlign="center" variant="body1">Ejemplo: +54 9 11 4011 1123</Typography>
            </Grid>
            
            <Grid item style={{minWidth:'30em'}}>
                <TextField 
                    id="whatsapp" 
                    name="whatsapp" 
                    size="small" 
                    fullWidth 
                    disabled={status?.sending || status?.sended}
                    placeholder="Numero de whatsapp"  
                    value={whatsapp} 
                    onChange={(event)=>setWhatsapp(event.target.value)}
                    inputProps={{style:{textAlign:'center'}}}>
                </TextField>
            </Grid>
            
            <Grid item>
                <Button variant="contained" onClick={handleSendCode} disabled={status?.sending || status?.sended}>Enviar codigo</Button>
            </Grid>

            {
                status?.sending &&
                <Grid item>
                    <LinearProgress color="primary" style={{ height: '0.5em', minWidth:'10em' }}/>
                </Grid>
            }

            {
                !status.sended && status?.error &&
                <div style={{display:'flex', alignItems:'center', marginTop:'2em'}}>
                    <Warning></Warning>
                    <Typography textAlign="center">{status?.error}</Typography>
                </div>
            }


            {   
                status.sended &&
                <Fragment>
                    <Grid item style={{marginTop:'3em'}}>
                        <Typography textAlign="center">Te enviamos un codigo.</Typography>
                        <Typography textAlign="center">Ingresalo a continuacion.</Typography>
                    </Grid>
                    <Grid item style={{minWidth:'30em'}}>
                        <TextField 
                            id="verification_code" 
                            name="verification_code" 
                            fullWidth  
                            size="small"  
                            placeholder="Codigo de verificacion" 
                            disabled={status.valid || status.validating}
                            value={code} 
                            onChange={(event)=>setCode(event.target.value)}
                            inputProps={{style:{textAlign:'center'}}}
                        ></TextField>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" onClick={handleVerifyCode} disabled={status?.validating || status?.valid}>Verificar</Button>
                    </Grid>


                    {
                        status?.validating &&
                        <Grid item>
                            <LinearProgress color="primary" style={{ height: '0.5em', minWidth:'10em' }}/>
                        </Grid>
                    }

                    {
                        status?.valid &&
                        <div style={{display:'flex',flexDirection:'column', alignItems:'center', marginTop:'2em'}}>
                            <Typography textAlign="center">Listo!</Typography>
                            <Typography textAlign="center">Tu whatsapp quedo registrado.</Typography>
                        </div>
                    }

                    {
                        status?.error &&
                        <div style={{display:'flex', alignItems:'center', marginTop:'2em'}}>
                            <Warning></Warning>
                            <Typography textAlign="center">{status?.error}</Typography>
                        </div>
                    }

                    {
                        status?.error &&
                        <Grid item>
                            <Button variant="contained" onClick={handleReset} >Reiniciar</Button>

                        </Grid>
                    }



                </Fragment>
            }

            <Grid item>
                <Link style={{ textDecoration: 'none' }} to={"/"}>
                    <Button variant="outlined" color="primary" style={{marginTop:'1em'}}>Volver</Button>
                </Link>
            </Grid>
        </Grid>
    </Grid>
)
};

export default PatientLoadWhatsapp;