import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchMonthInterval: "FETCH_MONTH_INTERVAL",
    receiveMonthInterval: "RECEIVE_MONTH_INTERVAL"
};

const receiveMonthInterval = (interval) => ({ type: actionTypes.receiveMonthInterval, interval });

export const fetchMonthInterval = (months) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchMonthInterval });

        const http = new HttpService();

        return http.getData(`intervals/month/${months}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(interval => {
                dispatch(receiveMonthInterval(interval));
            })
    }
}