import { combineReducers } from 'redux';
import { actionTypes } from '../actions/verification';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchVerification:
            return true
        case actionTypes.receiveVerification:
            return false
        default:
            return state
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchVerification:
            return {}
        case actionTypes.receiveVerification:
            return action.verification
        case actionTypes.clearVerification:
            return {}
        default:
            return state

    }
}

export default combineReducers({ loading, data });