import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { store } from "./store";

import './App.css';
import { ThemeProvider } from "@mui/system";
import Theme from "./ui/Theme";
import PatientSearch from "./views/PatientSearch";
import Orders from "./views/Orders";
import ClinicalView from "./views/ClinicalView";
import TopBar from "./views/TopBar";
import ResultsView from "./views/ResultsView";

import { LicenseInfo } from '@mui/x-data-grid-pro';
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./views/Login";
import Profile from "./views/Profile";
import PatientSignup from "./views/PatientSignup";
import PatientSignupPassword from "./views/PatientSignupPassword";
import PhysicianSignup from "./views/PhysicianSignup";
import UserAdmin from "./views/UserAdmin";
import ForgotPassword from "./views/ForgotPassword";
import VerificationCode from "./views/VerificationCode";
import CreatePassword from "./views/CreatePassword";
import Register from "./views/Register";
import PatientSignupEmail from "./views/PatientSignupEmail";
import useLogs from "./reducers/useLogs";
import Board from "./views/Board";
import DocumentValidator from "./views/DocumentValidator";
import PhysicianVerification from "./views/PhysicianVerification";
import ReportDownloader from "./views/ReportDownloader";
import PatientLoadWhatsapp from "./views/PatientLoadWhatsapp";

LicenseInfo.setLicenseKey(
  '97a73f5f420df5daed4dd9e623e72708T1JERVI6MzQ0NjQsRVhQSVJZPTE2NzEyNzcwMjQwMDAsS0VZVkVSU0lPTj0x',
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={Theme}>
        <BrowserRouter basename={'/'}>
          <div style={{ height: '100vh', overflow: 'hidden' }}>
            <TopBar />
            <div style={{ height: '4em' }}></div>
            <div style={{ height: '100%' }}>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="document-validator/:order/:key" element={<DocumentValidator/>} />
                <Route path="report/:token" element={<ReportDownloader/>} />
                <Route path="admin/users" element={<ProtectedRoute Component={UserAdmin} scopes={["admin"]} />} />
                <Route path="signup/physician" element={<PhysicianSignup />} />
                <Route path="signup/physician/:key" element={<PhysicianVerification />} />
                <Route path="signup/patient" element={<PatientSignupEmail />} />
                <Route path="signup/patient/:key" element={<PatientSignup type="email" />} />
                <Route path="signup/patient/email/:key" element={<PatientSignup type="email" />} />
                <Route path="signup/patient/whatsapp/:key" element={<PatientSignup type="whatsapp" />} />
                <Route path="signup/patient/password/:key" element={<PatientSignupPassword />} />
                <Route path="profile" element={<Profile />} />
                <Route path="whatsapp" element={<ProtectedRoute Component={PatientLoadWhatsapp} scopes={["patient"]} />}></Route>
                <Route path="patient-search" element={<ProtectedRoute Component={PatientSearch} scopes={["admin","physician"]} />}></Route>
                <Route path="orders/:id" element={<ProtectedRoute Component={Orders} scopes={["any"]} />}></Route>
                <Route path="results/:number" element={<ProtectedRoute Component={ResultsView} scopes={["any"]} />}></Route>
                <Route path="reports/:id" element={<ProtectedRoute Component={ResultsView} scopes={["any"]} />}></Route>
                <Route path="clinical-view/:id" element={<ProtectedRoute Component={ClinicalView} scopes={["admin","physician"]} />}></Route>
                <Route path="forgot/verification/:key" element={<VerificationCode/>}></Route>
                <Route path="forgot/password/reset/:key" element={<CreatePassword/>}></Route>
                <Route path="forgot" element={<ForgotPassword/>}></Route>
                <Route path="register" element={<Register/>}></Route>
                <Route path="board" element={<ProtectedRoute Component={Board} scopes={["admin"]} />}></Route>
                
              </Routes>
            </div>
          </div>

        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
