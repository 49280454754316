import { combineReducers } from 'redux';
import { actionTypes } from '../actions/dataware';


const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchOrders:
            return true
        case actionTypes.fetchUsers:
            return true
            case actionTypes.receiveOrders:
                return false
            case actionTypes.receiveUsers:
                return false
            default:
            return state
    }
}

const orders = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchOrders:
            return {}
        case actionTypes.clearOrders:
            return {}
        case actionTypes.receiveOrders:
            return action.orders
        default:
            return state
    }
}

const users = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchUsers:
            return {}
        case actionTypes.clearUsers:
            return {}
        case actionTypes.receiveUsers:
            return action.users
        default:
            return state
    }
}

export default combineReducers({ loading, orders,users });