import React, { Fragment, useEffect, useState } from 'react';
import { Button, IconButton, LinearProgress, useMediaQuery } from '@mui/material';
import { Download, Visibility,Timer } from '@mui/icons-material';

import OrderStatus from './OrderStatus';
import PatientHeader from './PatientHeader';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearOrders, fetchOrders } from '../actions/orders';
import { useSelector } from 'react-redux';
import { selectOrders } from '../selectors/orders';
import { selectPatient } from '../selectors/patient';
import { clearPatient, fetchPatient } from '../actions/patient';
import { clearResults } from '../actions/results';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { format, parseISO } from 'date-fns';
import { useTheme } from '@mui/styles';
import { isAvailable, isReportAvailable } from '../translators/status';
import { fetchDocument } from '../actions/document';
import { selectProfile } from '../selectors/profile';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SearchIcon from '@mui/icons-material/Search';
import OrderAdminStatus from './OrderAdminStatus';
import PendingStatusDialog from './PendingStatusDialog';

const Orders = () => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const {id} = useParams();
    const [showPendingDialog, setPendingDialog] = useState(false);

    useEffect(() => {
        dispatch(fetchPatient(id, null));
        dispatch(fetchOrders(id));

        return () => {
            dispatch(clearPatient);
            dispatch(clearOrders);
        }
    }, []);

    const orders = useSelector(selectOrders);
    const patient = useSelector(selectPatient);
    const profile = useSelector(selectProfile);

    const handleDownloadResult = (order) => {
        const lastDocument = order?.documents?.slice(-1)[0];
        dispatch(fetchDocument(lastDocument?.id, order?.number));
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            width: 250,
            sortable: false,
            hide: true,
            type: 'number'
        },
        {
            field: 'date',
            headerName: 'Fecha de extraccion',
            sortable: false,
            flex: 1,
            type: 'date',
            minWidth: 85,
            valueGetter: (params) => format(parseISO(params.value), 'dd/MM/yyyy')
        },
        {
            field: 'lis_type',
            headerName: 'Tipo',
            sortable: false,
            flex: 1,
            hide: matchMD,
            type: 'string'
        },
        {
            field: 'origin',
            headerName: 'Sede',
            sortable: false,
            flex: 1,
            hide: matchMD,
            type: 'string',
            valueGetter: (params) => params.row.origin.name
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => <OrderStatus {...{order:params.row, role:profile?.data?.role?.code}}></OrderStatus>,

        },
        {
            field: 'admin_status',
            headerName: 'Estado Adm.',
            sortable: false,
            flex: 0.5,
            minWidth: 110,
            renderCell: (params) => <OrderAdminStatus status={params.value}></OrderAdminStatus>,
        },
        {
            field: 'number',
            headerName: 'Numero',
            minWidth: 110,
            sortable: false,
            flex: 0.5,
            type: 'string'

        },
        {
            field: 'view_results',
            headerName: 'Ver',
            minWidth: 15,
            renderCell: (params) => isAvailable(params.row, profile?.data?.role?.code) && <Link to={`/results/${params.row.number}`}><IconButton ><Visibility color="primary"></Visibility></IconButton></Link>,
            type: 'actions',
            resizable: false,

        },
        {
            field: 'view_report',
            headerName: 'Descargar',
            minWidth: 15,
            renderCell: (params) => isReportAvailable(params.row,profile?.data?.role?.code) && <IconButton onClick={() => handleDownloadResult(params.row)}><Download color="primary"></Download></IconButton>,
            type: 'actions',
            resizable: false,
        },

    ];

    const columns_mobile = [
        {
            field: 'id',
            headerName: 'Id',
            width: 250,
            sortable: false,
            hide: true,
            type: 'number'
        },
        {
            field: 'date',
            headerName: 'Fecha',
            sortable: false,
            flex: 1,
            type: 'date',
            minWidth: 85,
            valueGetter: (params) => format(parseISO(params.value), 'dd/MM/yyyy')
        },
        {
            field: 'lis_type',
            headerName: 'Tipo',
            sortable: false,
            flex: 1,
            hide: matchMD,
            type: 'string'
        },
        {
            field: 'origin',
            headerName: 'Sede',
            sortable: false,
            flex: 1,
            hide: matchMD,
            type: 'string',
            valueGetter: (params) => params.row.origin.name
        },
        {
            field: 'status',
            headerName: 'Estado',
            sortable: false,
            flex: 0.1,
            hide: matchMD,
            minWidth: 60,
            renderCell: (params) => <OrderStatus {...{order:params.row, role:profile?.data?.role?.code}}></OrderStatus>,

        },
        {
            field: 'admin_status',
            headerName: 'Estado Adm.',
            sortable: false,
            flex: 0.1,
            hide: matchMD,
            minWidth: 60,
            renderCell: (params) => <OrderAdminStatus status={params.value}></OrderAdminStatus>,
        },
        {
            field: 'number',
            headerName: 'Numero',
            minWidth: 90,
            sortable: false,
            
            flex: 0.5,
            type: 'string'
        },
        {
            field: 'view_results',
            headerName: 'Ver',
            minWidth: 15,
            renderCell: (params) => 
                <div style={{display:'flex' , justifyContent:'flex-end',  width:'100%'}}>
                    {isAvailable(params.row, profile?.data?.role?.code) && <Link to={`/results/${params.row.number}`}><IconButton ><Visibility color="primary"></Visibility></IconButton></Link>}
                    {isReportAvailable(params.row,profile?.data?.role?.code) && <IconButton onClick={() => handleDownloadResult(params.row)}><Download color="primary"></Download></IconButton>}
                    {!isAvailable(params.row, profile?.data?.role?.code) && !isReportAvailable(params.row,profile?.data?.role?.code) && <IconButton onClick={() => setPendingDialog(true)}><Timer color="primary"/></IconButton>}
                </div>
                ,
            type: 'actions',
            resizable: false,
        }
    ];

    const data = {
        rows: orders.data,
        columns: matchMD ? columns_mobile : columns
    }

    return (
        <div style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <div >
                <PatientHeader patient={patient?.data} age="" ></PatientHeader>
            </div>
            <div style={{ marginBottom: '1em' }}>
                {
                    orders.loading &&
                    <LinearProgress />
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em', marginLeft: matchMD ? '0.5em' : '3em', marginRight: matchMD ? '0.5em' : '3em', justifyContent: 'flex-end' }}>
                {
                    profile.data?.role?.code === "physician" || profile.data?.role?.code === "admin" &&
                    <Fragment>
                        <div style={{ paddingLeft: '1em' }}>
                            <Link to={`/clinical-view/${patient?.data?.id}`} >
                                <Button fullWidth variant="outlined">
                                    <LocalHospitalIcon color="primary"></LocalHospitalIcon></Button>
                            </Link>
                        </div>

                        <div style={{ paddingLeft: '1em' }}>
                            <Link to={`/patient-search`} >
                                <Button fullWidth variant="outlined">
                                    <SearchIcon color="primary"></SearchIcon></Button>
                            </Link>
                        </div>
                    </Fragment>
                }
            </div>
            <div style={{ flexGrow: '1', overflowY: 'auto', marginLeft: matchMD ? '0.5em' : '3em', marginRight: matchMD ? '0.5em' : '3em' }}>
                <DataGridPro
                    {...data}
                    headerHeight={60}
                    rowHeight={40}
                    disableMultipleSelection
                    disableColumnMenu
                    disableColumnSelector
                    disableMultipleColumnsSorting
                    hideFooter
                />
            </div>
            {
                showPendingDialog && <PendingStatusDialog open={showPendingDialog} close={()=>setPendingDialog(false)} />
            }
        </div >
    );
};

export default Orders;