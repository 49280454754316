import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchOrder: 'FETCH_ORDER',
    receiveOrder: 'RECEIVE_ORDER',
    setOrder: "SET_ORDER",
    clearOrder: "CLEAR_ORDER"
};

export const setOrder = (order) => ({ type: actionTypes.setOrder, order });
export const receiveOrder = (order) => ({ type: actionTypes.receiveOrder, order });
export const clearOrder = { type: actionTypes.clearOrder };

export const fetchOrder = (order_number) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrder });

        const http = new HttpService();

        return http.getData(`orders/${order_number}`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(order => {
                dispatch(receiveOrder(order));
            })
    }
}