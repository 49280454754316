import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

const ReferenceText = ({referenceText}) => {
    return (
        <Grid direction="column" >
            <Typography>
                <Box fontWeight="medium">Referencia</Box>
            </Typography>
            <Divider />
            {referenceText.map(text => <div>{text}</div>)}
        </Grid>
    )
}

export default ReferenceText;
