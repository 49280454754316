import HttpService from "../services/HttpService";

export const actionTypes = {
    sendCode :'WHATSAPP_CODE_SEND',
    deliverCode : 'WHATSAPP_CODE_DELIVERED',
    validateCode:'WHATSAPP_CODE_VALIDATE',
    acceptCode:'WHATSAPP_CODE_ACCEPT',
    rejectCode:'WHATSAPP_CODE_REJECT',
    reset:'WHATSAPP_RESET',
    error:'WHATSAPP_ERROR'
};

export const reset = () => ({ type: actionTypes.reset });

export const sendCode = (whatsapp) => {
    return (dispatch) => {

        dispatch({type:actionTypes.sendCode});

        const http = new HttpService();
        return http.getData(`custom/whatsapp/sendcode/${whatsapp}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {
                if (response.error)
                {
                    dispatch({type:actionTypes.error, error: response.error});
                }
                else
                {
                    dispatch({type:actionTypes.deliverCode, verificationToken:response.verification_token});
                }
            })
    }
};


export const validateCode = (token, code,whatsapp) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.validateCode });

        const http = new HttpService();
        return http.getData(`custom/whatsapp/validate/${token}/code/${code}/whatsapp/${whatsapp}`, '')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
            })
            .then(response => {
                if (response.error)
                {
                    dispatch({ type: actionTypes.rejectCode });
                    dispatch({type:actionTypes.error, error: response.error});
                }
                else
                {
                    dispatch({ type: actionTypes.acceptCode });
                }
            })
    }
};
