import { combineReducers } from 'redux';
import { actionTypes } from '../actions/documentToken';

const error = (state = "", action) => {
    switch (action.type) {
        case actionTypes.fetchDocumentToken:
            return ""
        case actionTypes.receiveDocumentToken:
            return ""
        case actionTypes.rejectDocumentToken:
            return action.error
        case actionTypes.clearDocumentToken:
            return ""
        default:
            return state
    }
}
const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchDocumentToken:
            return true
        case actionTypes.receiveDocumentToken:
            return false
        case actionTypes.rejectDocumentToken:
            return false
        case actionTypes.clearDocumentToken:
            return false
        default:
            return state
    }
}

const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.fetchDocumentToken:
            return {}
        case actionTypes.receiveDocumentToken:
            return action.documentToken
        case actionTypes.clearDocumentToken:
            return {}
        default:
            return state

    }
}

export default combineReducers({ loading, data,error });