import { useTheme } from '@emotion/react';
import { Button, LinearProgress, TextField, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { confirmUser, validateToken } from '../actions/signup';
import MobileDate from '../components/MobileDate';
import { selectSignupData } from '../selectors/signup';


const PatientSignup = ({type}) => {

    const { key } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    const signupData = useSelector(selectSignupData);
    const [userData, setUserData] = useState({ firstName: '', lastName: '', birthDay: '' });

    useEffect(() => {
        dispatch(validateToken(key));
    }, []);

    useEffect(() => {
        if (signupData.userStatus.valid && !signupData.userStatus.validating) {
            navigate(`/signup/patient/password/${key}`);
        }
    }, [signupData.userStatus]);

    const handleSave = () => {
        dispatch(confirmUser({ userData: { ...userData }, signupToken: key, type:type }))
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Formulario de Alta</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    
                <div style={{ display:'flex',marginBottom: '1em' }}>
                    <Typography>Ingrese fecha de nacimiento, nombre/s y apellido <br/> tal como figura en el comprobante de atención.</Typography>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '20em' }}>

                    <div style={{ marginBottom: '1em' }}>
                        <TextField size="small" variant="outlined" placeholder="Nombre" fullWidth value={userData.firstName} disabled={!signupData.tokenStatus.valid || signupData.tokenStatus.validating} onChange={(event) => setUserData({ ...userData, firstName: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField size="small" variant="outlined" placeholder="Apellido" fullWidth value={userData.lastName} disabled={!signupData.tokenStatus.valid || signupData.tokenStatus.validating} onChange={(event) => setUserData({ ...userData, lastName: event.target.value })} />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        {
                            matchMD &&
                            <MobileDate onSet={(value)=>setUserData({ ...userData, birthDay: `${value.year}-${value.month}-${value.day}`})}/>
                        }

                        {
                            !matchMD &&
                            <TextField InputLabelProps={{ shrink: true }}  size="small" variant="outlined" type="date" label="Fecha de nacimiento" fullWidth value={userData?.birthDay || ''} disabled={!signupData.tokenStatus.valid || signupData.tokenStatus.validating} onChange={(event) => setUserData({ ...userData, birthDay: event.target.value })} />
                        }

                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Button size="small" variant="contained" fullWidth disabled={!signupData.tokenStatus.valid || signupData.tokenStatus.validating} onClick={() => handleSave()}>Continuar</Button>
                    </div>
                    <div style={{ height: '1em' }}>
                        {
                            (signupData.tokenStatus.validating || signupData.userStatus.validating) && <LinearProgress color="primary" />
                        }
                    </div>

                    {
                        signupData.tokenStatus.userVerified && !signupData.tokenStatus.validating &&
                        <div style={{ height: '1em' }}>
                            <Typography>Usted ya esta registrado. Dirijase al login y si es necesario recupere su contraseña.</Typography>
                            <Link to="/" style={{textDecoration:'none'}}>
                                <Button size="small" variant="outlined" fullWidth>Login</ Button>
                            </Link>
                        </div>
                    }

                    {
                        !signupData.tokenStatus.valid && !signupData.tokenStatus.userVerified && !signupData.tokenStatus.validating &&
                        <div style={{ height: '1em' }}>
                            <Typography>El link de registro tiene una vigencia de 90 dias y esta vencido.</Typography>
                            <Typography>Hemos enviado un nuevo link de registro a tu correo.</Typography>
                        </div>
                    }

                    {
                        signupData.userStatus.error && !signupData.userStatus.validating &&
                        <div style={{ height: '1em' }}>
                            <Typography>Los datos ingresados no corresponden con los registrados.</Typography>
                        </div>
                    }

                </div>
            </div>
        </div >
    );
};

export default PatientSignup;