import React from "react";
import { Typography } from "@mui/material";

const UserStatus = ({id, enabled, email_verified_at,whatsapp_verified_at, role}) => {

    return role?.code === 'patient' ? 
        <Typography>{email_verified_at || whatsapp_verified_at ? 'Registrado' : 'No registrado'}</Typography> :
        <Typography>{enabled ? 'Activo' : 'Inactivo'}</Typography>
}

export default UserStatus;
