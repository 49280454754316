import React from "react";
import { Grid, LinearProgress, TextField } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { selectPhysicians } from "../selectors/physicians";
import { searchPhysician } from "../actions/physicians";
import { useState } from "react";

const columns = [
    {
        field: 'id',
        headerName: 'Id',
        width: 150,
        sortable: false,
        hide: true
    },
    {
        field: 'license',
        headerName: 'Matricula',
        width: 200,
        sortable: false
    },
    {
        field: 'last_name',
        headerName: 'Apellido',
        width: 200,
        sortable: false
    },
    {
        field: 'first_name',
        headerName: 'Nombre',
        width: 200,
        sortable: false
    },
    {
        field: 'lis_id',
        headerName: 'LIS',
        width: 150,
        sortable: false
    }
];

const PhysicianSearch = ({onSelect}) => {

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useState({ license: '', last_name: '', first_name: '' });
    const canSearch = !!searchParams.license || !!searchParams.last_name || !!searchParams.first_name;

    const physicians = useSelector(selectPhysicians);
    const data = {rows:physicians.data, columns:columns};

    const handleKeyPress = (event) => {
        if (event.keyCode === 13 && canSearch) {
            dispatch(searchPhysician(searchParams));
        }
    }

    return <Grid container direction="column" style={{minHeight:'40vh'}}>
            <Grid item container spacing={1}>
                <Grid item xs>
                    <TextField fullWidth size="small" placeholder="Apellido" value={searchParams.last_name} onKeyDown={handleKeyPress} onChange={(event) => setSearchParams({ ...searchParams, last_name: event.target.value })}/>
                </Grid>
                <Grid item xs>
                    <TextField fullWidth size="small" placeholder="Nombre" value={searchParams.first_name} onKeyDown={handleKeyPress} onChange={(event) => setSearchParams({ ...searchParams, first_name: event.target.value })}/>
                </Grid>
                <Grid item xs>
                    <TextField fullWidth size="small" placeholder="Matricula" value={searchParams.license} onKeyDown={handleKeyPress} onChange={(event) => setSearchParams({ ...searchParams, license: event.target.value })}/>
                </Grid>
            </Grid>
            <Grid item>
                {
                    physicians.loading &&
                    <LinearProgress/>
                }

            </Grid>
            <Grid item xs style={{marginTop:'1em'}}>
                <DataGridPro
                    {...data}
                    disableMultipleSelection
                    disableColumnMenu
                    disableMultipleColumnsSorting
                    hideFooter
                    onCellClick={(params) => onSelect(params.row)}
                />
            </Grid>
        </Grid>
};

export default PhysicianSearch;