import { combineReducers } from 'redux';
import { actionTypes } from '../actions/forgot';

const statusTemplate = {
    email: { valid: false, validating: false, error:''},
    verification: { valid: false, validating: false, error: '' },
    change: { valid: false, validating: false, error: '' },
    password: { changed: false, changing: false, error: '' }
    
}

const verificationToken = (state = "", action) => {
    switch (action.type) {
        case actionTypes.setVerificationToken:
            return action.token
        default:
            return state
    }
}

const changeToken = (state = "", action) => {
    switch (action.type) {
        case actionTypes.setChangeToken:
            return action.token;
        default:
            return state
    }
}

const status = (state = statusTemplate, action) => {
    switch (action.type) {
        case actionTypes.validatingEmail:
            return {...state, email:{valid:false,validating:true, error:''},password:{changed:false, changing:false,error:''}}
        case actionTypes.acceptEmail:
            return {...state, email:{valid:true,validating:false, error:''}}
        case actionTypes.refuseEmail:
            return {...state, email:{valid:false,validating:false, error:'El email o whatsapp no es valido o no ha completado el registro.'}}
        case actionTypes.validatingVerificationToken:
            return {...state, email:{valid:false,validating:false,error:''}, verification:{valid:false,validating:true, error:''}}
        case actionTypes.acceptVerificationToken:
            return {...state, verification:{valid:true,validating:false, error:''}}
        case actionTypes.refuseVerificationToken:
            return {...state, verification:{valid:false,validating:false, error:action.error}}
        case actionTypes.changingPassword:
            return {...state, verification:{valid:false, validating:false, error:''}, password:{changed:false,changing:true, error:''}}
        case actionTypes.acceptPassword:
            return {...state, password:{changed:true,changing:false, error:''}}
        case actionTypes.refusePassword:
            return {...state, password:{changed:false,changing:false, error:action.error}}
        default:
            return state
    }
}

export default combineReducers({verificationToken, changeToken, status });
