import { useTheme } from '@emotion/react';
import { EmailOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { validateCode, validateVerificationToken } from '../actions/forgot';
import { selectForgotData } from '../selectors/forgot';


const VerificationCode = () => {

    const { key } = useParams();
    const theme = useTheme();

    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const {changeToken, status} = useSelector(selectForgotData);

    const [code,setCode] = useState({ch_1:"",ch_2:"",ch_3:"",ch_4:"",ch_5:"",ch_6:""});

    useEffect(() => {
        status.verification.valid && navigate(`/forgot/password/reset/${changeToken}`);
    }, [status]);

    const handleChange = (event) => {

        const { value, id,name } = event.target;
        const [fieldName, fieldIndex] = id.split("_");

        setCode({...code, [id]: value.slice(-1).toUpperCase()})

        let fieldIntIndex = parseInt(fieldIndex, 10);
        const nextfield = document.querySelector(`input[id=ch_${fieldIntIndex + 1}]`);

        if (nextfield !== null && value !== '') {
        nextfield.focus();
        }


    }

    const handleContinue = () => {
        dispatch(validateCode(`${code.ch_1}${code.ch_2}${code.ch_3}${code.ch_4}${code.ch_5}${code.ch_6}`, key));
    };

    const validForm = code.ch1 != "" && code.ch2 != "" && code.ch3 != "" && code.ch4 != "" && code.ch5 != "" && code.ch6 != "";

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Recuperar contraseña</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column',  alignItems: 'center', minWidth: '20em', minHeight: '2s0em' }}>

                    <div style={{ marginBottom: '1em', alignContent:'center' }}>
                        <IconButton style={{color:'white',backgroundColor:theme.palette.arenco.green}}>
                            <EmailOutlined></EmailOutlined>
                        </IconButton>
                    </div>

                    <div style={{ marginBottom: '2em' }}>
                        <Typography>
                            Revisa tu email y carga el codigo de verificacion
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:'row', justifyContent:'center', marginBottom: '2em' }}>
                        <TextField style={{width:'2.8em',margin:'0.1em'}} id="ch_1" size="small" variant="outlined" value={code?.ch_1||''} onChange={handleChange} />
                        <TextField style={{width:'2.8em',margin:'0.1em'}} id="ch_2" size="small" variant="outlined" value={code?.ch_2||''} onChange={handleChange} />
                        <TextField style={{width:'2.8em',margin:'0.1em'}} id="ch_3" size="small" variant="outlined" value={code?.ch_3||''} onChange={handleChange} />
                        <TextField style={{width:'2.8em',margin:'0.1em'}} id="ch_4" size="small" variant="outlined" value={code?.ch_4||''} onChange={handleChange} />
                        <TextField style={{width:'2.8em',margin:'0.1em'}} id="ch_5" size="small" variant="outlined" value={code?.ch_5||''} onChange={handleChange} />
                        <TextField style={{width:'2.8em',margin:'0.1em'}} id="ch_6" size="small" variant="outlined" value={code?.ch_6||''} onChange={handleChange} />
                    </div>
                    
                    <div>
                        <Typography>{status.verification?.error}</Typography>
                    </div>

                    <div style={{ height: '1em' }}>
                        {
                            status.email.validating && <LinearProgress color="secondary" />
                        }
                    </div>


                    <div style={{ marginBottom: '1em' }}>
                        <Button
                            variant="contained"
                            fullWidth
                            disabled={!validForm}
                            onClick={() => handleContinue()}>Verificar</Button>
                    </div>

                    <div style={{ height: '1em' }}>

                        {   
                            //userData?.physicianUserStatus?.creating &&
                            //<LinearProgress color="primary" />
                        }
                    </div>


                </div>
            </div>
        </div >
    );
};

export default VerificationCode;