import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Study from './Study';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrder } from '../selectors/order';
import { selectPatient } from '../selectors/patient';
import { selectResults } from '../selectors/results';
import { clearPatientResults, clearResults, fetchResults } from '../actions/results';
import { Link, useParams } from 'react-router-dom';
import PatientHeader from './PatientHeader';
import Studies from './Studies';
import { clearPatient, fetchPatient } from '../actions/patient';
import { clearOrder, fetchOrder } from '../actions/order';
import { getAge } from '../helpers/ageCalculator';
import { Download } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button } from '@mui/material';
import { fetchDocument } from '../actions/document';
import SearchIcon from '@mui/icons-material/Search';
import { selectProfile } from '../selectors/profile';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '90vh',
        width: '100%',
        overflowY: 'auto',
    },
    appBarSpacer: {
        ...theme.mixins.toolbar,
    },
    headerContainer: {
        flex: '0 1 auto',
        marginBottom: '1em'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '75%',
        flex: '1 1 auto'
    },
    orderBox: {
        flexGrow: 1,
    },
    patientBox: {
        flexGrow: 1
    },
    results: {
        flexGrow: 4,
        overflowY: 'auto',
    },
    studies: {
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        minWidth: '25em',
        maxWidth: '25em',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
}));

const ResultsView = () => {

    const classes = useStyles();
    const { number } = useParams()
    const dispatch = useDispatch();

    const order = useSelector(selectOrder);
    const patient = useSelector(selectPatient);
    const results = useSelector(selectResults);
    const profile = useSelector(selectProfile);

    useEffect(() => {
        dispatch(fetchOrder(number));
        dispatch(fetchResults(number));

        return () => {
            dispatch(clearOrder);
            dispatch(clearResults);
            dispatch(clearPatient);
        }

    }, []);

    useEffect(() => {
        !patient.data.id && dispatch(fetchPatient(order?.data?.patient_id, null));
    }, [order.data.id]);

    const handleDownloadResult = (id, order_number) => {
        const lastDocument = order.data?.documents?.slice(-1)[0];

        if (lastDocument) {
            dispatch(fetchDocument(lastDocument.id, order_number));
        }
    }
    return (
        <div className={classes.mainContainer}>
            <div className={classes.headerContainer}>
                <PatientHeader patient={patient.data} age={patient.data?.birth_day && order.data.date && getAge(patient.data?.birth_day, order.data.date)} order={order.data} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em', marginLeft: '3em', marginRight: '3em', justifyContent: 'flex-end' }}>

                {
                    profile.data?.role?.code === "physician" &&
                    <div style={{ paddingLeft: '1em' }}>
                        <Link to={`/patient-search`} >
                            <Button fullWidth variant="outlined" >
                                <SearchIcon color="primary"></SearchIcon></Button>
                        </Link>
                    </div>
                }

                {
                    profile.data?.role?.code === "physician" &&
                    <div style={{ paddingLeft: '1em' }}>
                        <Link to={`/clinical-view/${patient?.data?.id}`} >
                            <Button fullWidth variant="outlined" >
                                <LocalHospitalIcon color="primary"></LocalHospitalIcon></Button>
                        </Link>
                    </div>
                }

                <div style={{ paddingLeft: '1em' }}>
                    <Link to={`/orders/${patient?.data?.id}`} >
                        <Button fullWidth variant="outlined" >
                            <FormatListBulletedIcon color="primary"></FormatListBulletedIcon></Button>
                    </Link>
                </div>
                <div style={{ paddingLeft: '1em' }}>
                    <Button fullWidth variant="outlined" onClick={() => handleDownloadResult(order.data.id, order.data.number)} >
                        <Download color="primary"></Download></Button>
                </div>
            </div>
            <div className={classes.contentContainer}>
                <div id="results_container" className={classes.results} >
                    {
                        results?.data.map(
                            study => study.status != 'cancelled' && study.tests.length > 0 && <Study key={study.id} study={study} />
                        )
                    }
                </div>
                <div className={classes.studies}>
                    <Studies studies={results?.data} />
                </div>
            </div>
        </div>
    );
};

export default ResultsView;