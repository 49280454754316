import React, { useEffect } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Download, Visibility, Warning } from '@mui/icons-material';
import { Button, Grid, IconButton, Tooltip } from '@mui/material';
import PatientHeader from './PatientHeader';
import OrderStatus from './OrderStatus';
import { useSelector } from 'react-redux';
import { selectPatient } from '../selectors/patient';
import { selectAllTestsFromPatientResults, selectPatientResults, selectUniqueTestsFromPatientResults } from '../selectors/results';

import { Link, useParams } from 'react-router-dom';
import { clearPatient, fetchPatient } from '../actions/patient';
import { useDispatch } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { clearPatientResults, fetchPatientResults } from '../actions/results';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { fetchDocument } from '../actions/document';
import { clearOrders, fetchOrders } from '../actions/orders';
import { selectOrders } from '../selectors/orders';
import { isAvailable } from '../translators/status';
import { selectProfile } from '../selectors/profile';

const OrderHeader = ({ order }) => {
    return (
        <div>
            <div style={{ fontWeight: 700, height: '1em' }}>#{order.number}</div>
            <div >{order.date}</div>
        </div>);
};

const OrderLinks = ({ order }) => {

    const dispatch = useDispatch();

    const handleDownloadResult = () => {
        const lastDocument = order?.documents?.slice(-1)[0];
        dispatch(fetchDocument(lastDocument?.id, order.number));
    }

    return (
        <div>
            {
                isAvailable(order.status, 'physician') &&
                <Link to={`/results/${order.number}`}><IconButton ><Visibility color="primary"></Visibility></IconButton></Link>
            }

            {
                order.status === 'posted' &&
                <IconButton onClick={handleDownloadResult}><Download color="primary"></Download></IconButton>
            }

        </div>
    )
}

const ResultCell = ({ result }) => {


    const isHigh = result?.reference_status === 'high';
    const isLow = result?.reference_status === 'low';

    const floatValue = parseFloat(result?.value);
    const formatedValue = floatValue && floatValue.toLocaleString(navigator.language, { minimumFractionDigits: result.decimals });


    return (
        <Grid container spacing={1} alignItems="center" >
            <Grid item ><strong>{formatedValue || result?.value}</strong></Grid>
            <Grid item>{result?.unit_of_measurement}</Grid>
            <Grid item>
                {isHigh && <ArrowDropUpIcon style={{ color: "#ee7a7d" }} />}
                {isLow && <ArrowDropDownIcon style={{ color: "#ee7a7d" }} />}
            </Grid>
            <Grid item>
                {result?.observation &&
                    <Tooltip title={result?.observation}>
                        <Warning style={{ color: "#ee7a7d" }} />
                    </Tooltip>}
            </Grid>
        </Grid >
    );
};

const createColumns = (orders,profile) => {

    let columns = [
        {
            field: 'test',
            headerName: '',
            width: 250,
            sortable: false,
            renderCell: (params) => <div>{params.row.test.name}</div>,
        }
    ];

    columns.push(...orders?.map(order => (
        {
            field: `order_${order.id}`,
            headerName: order.number,
            width: 200,
            sortable: false,
            renderHeader: (params) => <OrderHeader order={{ ...order }} />,
            renderCell: (params) => params.id === 0 ?
                <OrderStatus {...{order:order, role:profile?.data?.role?.code}} /> :
                params.id === 1 ?
                    <OrderLinks order={order} /> :
                    <ResultCell result={params.row[`order_${order.id}`].result} />,
        })));

    return columns;

}

const createRows = (uniqueTests, tests, orders) => {

    const rows = [];

    const firstRow = {
        id: 0,
        test: {}
    };

    const secondRow =
    {
        id: 1,
        test: {}
    }

    orders?.map(order => (firstRow[`order_${order.id}`] = order));
    rows.push(firstRow);

    orders?.map(order => (secondRow[`order_${order.id}`] = order));
    rows.push(secondRow);

    uniqueTests.forEach((test, index) => {

        const row = {
            id: index + 2,
            test: { code: test.test_type_code, name: test.test_type_name }
        };

        orders?.map(order => {
            const testFinded = tests.filter(testResult => testResult.order_id === order.id && testResult.test_type_code === test.test_type_code);
            return (row[`order_${order.id}`] = { result: testFinded.length && testFinded[0].result })
        });

        rows.push(row);
    })
    return rows;

}

const ClinicalView = () => {

    const patient = useSelector(selectPatient);
    const orders = useSelector(selectOrders);
    const uniqueTests = useSelector(selectUniqueTestsFromPatientResults);
    const tests = useSelector(selectAllTestsFromPatientResults);
    const profile = useSelector(selectProfile);

    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPatient(id,null));
        dispatch(fetchPatientResults(id));
        dispatch(fetchOrders(id));

        return () => {
            dispatch(clearPatient);
            dispatch(clearPatientResults);
            dispatch(clearOrders);
        };

    }, []);


    const data = { rows: tests.length ? createRows(uniqueTests, tests, orders.data) : [], columns: createColumns(orders.data, profile) };

    return (
        <div style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <div >
                <PatientHeader patient={patient?.data} age="" ></PatientHeader>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1em', marginLeft: '3em', marginRight: '3em', justifyContent: 'flex-end' }}>

                <div style={{ paddingLeft: '1em' }}>
                    <Link to={`/orders/${patient?.data?.id}`} >
                        <Button fullWidth variant="outlined" >
                            <FormatListBulletedIcon color="primary"></FormatListBulletedIcon></Button>
                    </Link>
                </div>

                <div style={{ paddingLeft: '1em' }}>
                    <Link to={`/patient-search`} >
                        <Button fullWidth variant="outlined" >
                            <SearchIcon color="primary"></SearchIcon></Button>
                    </Link>
                </div>


            </div>
            <div style={{ flexGrow: '1', overflowY: 'auto', marginLeft: '3em', marginRight: '3em', marginTop: '2em' }}>
                {
                    tests?.length > 0 &&
                    <DataGridPro
                        {...data}
                        headerHeight={40}
                        disableMultipleSelection
                        disableColumnMenu
                        disableColumnSelector
                        disableMultipleColumnsSorting
                        initialState={{ pinnedColumns: { left: ['test'] } }}
                        hideFooter
                    />}
            </div>
        </div>
    );
};

export default ClinicalView;