import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchUseLogs: "FETCH_USE_LOGS",
    receiveUseLogs: "RECEIVE_USE_LOGS",
    clearUseLogs : "CLEAR_USE_LOGS"
};

const receiveUseLogs = (useLogs) => ({ type: actionTypes.receiveUseLogs, useLogs });

export const clearUseLogs = { type: actionTypes.clearUseLogs };

export const fetchUseLogs = (from,to) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchUseLogs });

        const http = new HttpService();

        return http.getData(`uselogs?from=${from}&to=${to}`)
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(useLogs => {
                dispatch(receiveUseLogs(useLogs));
            })
    }
}
