import { Accessibility, Info, InfoOutlined, MedicalServices, MedicalServicesOutlined, MedicalServicesSharp } from "@mui/icons-material";
import { Button, Grid, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { useTheme, useThemeVariants } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isPhysicianRegistryEnabled } from "../selectors/settings";

const Register = ({}) => {

    const navigate = useNavigate();
    const theme = useTheme();
    const matchMD = useMediaQuery(theme.breakpoints.down('md'));

    

return(

        <div style={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
        
            <div id="header" style={{ backgroundColor: '#f4f4f4', display: 'flex', flexDirection: 'column',justifyContent:'center', minHeight:!matchMD ?'6em':'3em'}}>
                <Typography textAlign="center" variant="h5" color="text.black" >
                    <Box fontWeight={750}>Registro</Box></Typography>
            </div>

            <div id="content" style={{display: 'flex', flexDirection: 'column', alignItems:'center',marginTop:!matchMD?'3em':'1em'}}>
                <div id="content.header" style={{marginBottom:'2em'}}>
                    <Typography >Selecciona el tipo de acceso para ingresar</Typography>
                </div>

                <div id="content.items" style={{display: 'flex', flexDirection: !matchMD?'row':'column',alignItems:!matchMD?'flex-start':'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column', marginBottom:'1em', padding:!matchMD?'2em':'1em', minHeight: !matchMD?'10em':'8em', maxHeight: !matchMD?'10em':'8em', minWidth: !matchMD?'22em':'16em', maxWidth: !matchMD?'22em':'10em', backgroundColor:theme.palette.arenco.green}}>
                        <Link to="/signup/patient" style={{textDecoration:'none'}}>
                            <Grid container direction="column" alignItems="center">
                                <Grid item><Accessibility fontSize="large" style={{color:"white", minHeight:!matchMD?'3em':'2em', minWidth:"3em"}}/></Grid>
                                <Grid item><Typography color="white" variant="h6">SOY PACIENTE</Typography></Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        </Link>
                    </div>
                    <div style={{minWidth:'4em'}}/>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom:'1em', padding:!matchMD?'2em':'1em', minHeight: !matchMD?'10em':'8em', maxHeight: !matchMD?'10em':'8em', minWidth: !matchMD?'22em':'16em', maxWidth: !matchMD?'22em':'16em', backgroundColor:theme.palette.arenco.lightBlue}}>
                            <Link to="/signup/physician" style={{textDecoration:'none'}} >
                                    <Grid container direction="column" alignItems="center">
                                        <Grid item><MedicalServicesSharp  fontSize="large" style={{color:"white", minHeight:"3em", minWidth:"3em"}} /></Grid>
                                        <Grid item><Typography color="white" variant={!matchMD?"h6":"body1"}>PROFESIONAL DE LA SALUD</Typography></Grid>
                                        <Grid item xs>
                                        </Grid>
                                    </Grid>
                            </Link>
                        </div>
                        <div style={{minHeight:'1em'}}/>
                        <div style={{display: 'flex', flexDirection: 'column', marginBottom:'1em',  padding:!matchMD?'2em':'1em', minWidth: !matchMD?'22em':'16em', maxWidth: !matchMD?'22em':'16em', backgroundColor:'#f4f4f4'}}>
                            
                                <Grid container direction="row" alignItems="center">
                                    <Grid item xs={2}><InfoOutlined/></Grid>
                                    <Grid item xs>
                                        <Typography variant="body1">Este acceso es solo para profesionales que trabajan con Cebac Laboratorios.</Typography>
                                        
                                    </Grid>
                                </Grid>
                            
                        </div>
                    </div>
                </div> 
            </div>

            <div style={{flexGrow:2}}/>
    </div>)
}

export default Register;