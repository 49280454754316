import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchVerification: 'FETCH_VERIFICATION',
    receiveVerification : 'RECEIVE_VERIFICATION',
    clearVerification:'CLEAR_VERIFICATION'
};

export const receiveVerification = (verification) => ({ type: actionTypes.receiveVerification, verification });
export const clearVerification = () => ({ type: actionTypes.clearVerification });

export const fetchVerification = (order_number, report_key) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchVerification });

        const http = new HttpService();

        return http.getData(`custom/document-management/verification/${order_number}/${report_key}`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })
            .then(verification => {
                dispatch(receiveVerification(verification));
            })
    }
}

export const fetchVerificationDocument = (order_number,report_key) => {
    return (dispatch) => {

        const http = new HttpService();

        return http.getPDF(`custom/document-management/document/${order_number}/${report_key}`, 'user-token')
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Informe de resultados - Orden: ${order_number}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
    }
}