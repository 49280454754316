import { combineReducers } from 'redux';
import { actionTypes } from '../actions/order';
import { actionTypes as profileActions } from '../actions/profile';

const loading = (state = false, action) => {
    switch (action.type) {
        case actionTypes.fetchOrder:
            return true
        case actionTypes.receiveOrder:
            return false
        default:
            return state
    }
}
export const data = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.setOrder:
            return action.order
        case actionTypes.receiveOrder:
            return action.order
        case actionTypes.clearOrder:
            return {}
        case profileActions.clearProfile:
            return {}
        default:
            return state
    }
}

export default combineReducers({ loading, data });

