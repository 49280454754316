import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { changePassword } from '../actions/forgot';
import { createPassword } from '../actions/signup';
import { selectForgotData } from '../selectors/forgot';
import { selectSignupData } from '../selectors/signup';

const CreatePassword = () => {

    const { key } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState({ password: '', confirmationPassword: '' });

    const {changeToken, status} = useSelector(selectForgotData);

    const [passVisibility, setPassVisivility] = useState(false);
    const [confirmationVisibility, setConfirmationVisivility] = useState(false);

    const validPassword = data.password != '' && data.password != null && (data.password === data.confirmationPassword);

    useEffect(() => {
        status.password?.changed && navigate('/');
    }, [status]);

    const handleSave = () => {
        dispatch(changePassword(data.password, changeToken));
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <div style={{ height: '5em', display: 'flex', direction: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography textAlign="center" variant="h5" color="text.black" ><Box fontWeight={750}>Generacion de clave</Box></Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '20em', minHeight: '2s0em' }}>

                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            size="small"
                            type={!passVisibility ? "password" : "text"}
                            variant="outlined"
                            placeholder="Contraseña"
                            fullWidth
                            value={data.password}
                            onChange={(event) => setData({ ...data, password: event.target.value })}
                            InputProps={{
                                endAdornment:
                                <InputAdornment position="end">
                                    <div style={{backgroundColor:'transparent'}}>
                                    <IconButton
                                        style={{backgroundColor:'transparent'}} 
                                        aria-label="toggle password visibility"
                                        onClick={() => setPassVisivility(!passVisibility)}
                                    >
                                        {!passVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>

                                    </div>
                                </InputAdornment>}}

                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <TextField
                            size="small"
                            type={!confirmationVisibility ? "password" : "text"}
                            variant="outlined"
                            placeholder="Confirmar contraseña"
                            fullWidth
                            value={data.confirmationPassword}
                            onChange={(event) => setData({ ...data, confirmationPassword: event.target.value })}
                            InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setConfirmationVisivility(!confirmationVisibility)}
                                    >
                                        {!confirmationVisibility ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>}}
                        />
                    </div>
                    <div style={{ marginBottom: '1em' }}>
                        <Button
                            size="small"
                            variant="contained"
                            fullWidth
                            onClick={() => handleSave()}
                            disabled={!validPassword}>Guardar clave</Button>
                    </div>
                    <div style={{ height: '1em' }}>
                        {status.password.changing &&
                            <LinearProgress color="primary" />
                        }
                    </div>
                </div>
            </div>
        </div >);
};

export default CreatePassword;