import HttpService from "../services/HttpService";

export const actionTypes = {
    fetchOrders: "FETCH_ORDERS",
    receiveOrders: "RECEIVE_ORDERS",
    clearOrders: "CLEAR_ORDERS"
};

const receiveOrders = (orders) => ({ type: actionTypes.receiveOrders, orders });
export const clearOrders = { type: actionTypes.clearOrders };

export const fetchOrders = (id) => {
    return (dispatch) => {

        dispatch({ type: actionTypes.fetchOrders });

        const http = new HttpService();

        return http.getData(`patients/${id}/orders`, 'user-token')
            .then(response => {
                if (response?.ok) {
                    return response.json();
                }
                else return Promise.reject();
            })

            .then(orders => {
                dispatch(receiveOrders(orders));
            })
    }
}