import { actionTypes } from '../actions/settings';

const settings = (state = [], action) => {
    switch (action.type) {
        case actionTypes.fetchSettings:
            return []
        case actionTypes.receiveSettings:
            return action.settings
        default:
            return state
    }
}

export default settings;

