import Cookies from 'js-cookie'
import { clearProfile } from '../actions/profile';
import { store } from "./../store";


export default class HttpService {


    url = process.env.REACT_APP_API_URI || "/api";

    doLogin = async (item) => {

        const post = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(item)
        }

        return fetch(this.url + "/login", post)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    Cookies.remove('token');
                    Cookies.remove('refresh_token');
                    store.dispatch(clearProfile());
                }
                
                return response;
            })
    }

    postData = async (item, added_url, tokenId = "") => {
        const token = Cookies.get('token') || store.getState().profile.data.access_token;
        const requestOptions = this.postRequestOptions(token, item);

        return fetch(this.url + "/" + added_url, requestOptions)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    Cookies.remove('token');
                    Cookies.remove('refresh_token');
                    store.dispatch(clearProfile());
                }
                
                return response;
            })
    }

    putData = async (item, added_url, tokenId = "") => {
        const token = Cookies.get('token') || store.getState().profile.data.access_token;
        const requestOptions = this.putRequestOptions(token, item);

        return fetch(this.url + "/" + added_url, requestOptions)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    Cookies.remove('token');
                    Cookies.remove('refresh_token');
                    store.dispatch(clearProfile());
                }
                
                return response;
            })
    }

    getPDF = async (added_url, tokenId = "", queryParams = "") => {
        const token = Cookies.get('token') || store.getState().profile.data.access_token;
        const requestOptions = this.getRequestOptions(token, 'application/pdf');
        const params = queryParams ? '?' + new URLSearchParams(queryParams).toString() : '';

        return fetch(this.url + "/" + added_url + params, requestOptions)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    Cookies.remove('token');
                    Cookies.remove('refresh_token');
                    store.dispatch(clearProfile());
                }
                else return response;
            })
    }

    getData = async (added_url, tokenId = "", queryParams = "") => {
        const token = Cookies.get('token') || store.getState().profile.data.access_token;
        const requestOptions = this.getRequestOptions(token);
        const params = queryParams ? '?' + new URLSearchParams(queryParams).toString() : '';

        return fetch(this.url + "/" + added_url + params, requestOptions)
            .then(response => {
                if (response.status === 401 || response.status === 403) {
                    Cookies.remove('token');
                    Cookies.remove('refresh_token');
                    store.dispatch(clearProfile());
                }
                else return response;
            })
    }

    getRequestOptions = (token, contentType = null) => {
        let requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': contentType || 'application/json',
                'Accept': 'application/json',
            }
        }
        return requestOptions;
    }

    postRequestOptions = (token, item) => {
        let requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(item)
        }
        return requestOptions;
    }

    putRequestOptions = (token, item) => {
        let requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(item)
        }
        return requestOptions;
    }

    setAuthCookie = (token, refresh_token, days = 1) => {
        Cookies.set('token', token, { expires: days });
        Cookies.set('refresh_token', refresh_token, { expires: days })
    }

    removeAuthCookie = (token, refresh_token, days = 1) => {
        Cookies.remove('token');
        Cookies.remove('refresh_token');
    }

}